
































































































































































import { Vue } from "vue-property-decorator";
import PADetailCDP from "../processing-attempts/pa-detail/components/PADetailCDP.vue";
import ListModalHeader from "../modals/ListModalHeader.vue";
import { CdpDefaultSkeleton, LockedBy, OwnerUser } from "@/models/cdp";
import CDPService, {
  parseDataToKeyValueAndConvertPercentages,
} from "@/api/cdp/cdpService";
import { handleBeforeUnload } from "@/lib/utils";
import { PropType } from "vue";
import VToast from "@/components/vuemarc-ui-kit/toast/VToast.vue";
import PADetailTechDetail from "../processing-attempts/pa-detail/components/components/PADetailTechDetail.vue";

export default Vue.extend({
  components: {
    PADetailCDP,
    ListModalHeader,
    VToast,
    PADetailTechDetail,
  },
  name: "CDPModal",
  props: {
    showResourceDetail: { required: true, type: Boolean },
    activeInvoiceDocument: {
      required: false,
      type: Object as () => CdpDefaultSkeleton,
    },
    lastInvoiceDocumentFromDB: {
      required: false,
      type: Object as () => CdpDefaultSkeleton,
    },
    user: { required: false, type: Object as () => OwnerUser },
    loading: { required: false, type: Boolean },
    processingAttempt: { required: true, type: Object },
    lastVersionDocumentProcessProps: {
      required: true,
      type: Object as () => CdpDefaultSkeleton,
    },
    newVersionAvailable: { required: false, type: Boolean },
    isLockedBy: {
      required: true,
      type: null as unknown as PropType<LockedBy>,
    },
    isLastVersion: { required: true, type: Boolean },
    activeCDP: { required: true, type: String },
    loadingChangelog: { required: true, type: Boolean },
    changelogProp: { required: true, type: Array<any> },
    lockedByCurrentUser: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    async showDifferences() {
      if (this.showDifferences && !this.lockedByCurrentUser) {
        await this.lockInvoiceDocument();
      } else window.removeEventListener("beforeunload", handleBeforeUnload);
    },
  },
  data() {
    return {
      localInvoiceDocumentVersion: {} as CdpDefaultSkeleton,
      showDifferences: false,
      loadingLock: false,
    };
  },
  computed: {
    CDPPAStatusClass() {
      const CDPPAStatus: string = this.lastVersionDocumentProcessProps.status;

      return {
        "fa fa-check-circle":
          CDPPAStatus === "SUCCESS" || CDPPAStatus === "APPROVED",
        "fa fa-times-circle":
          CDPPAStatus === "ERROR" ||
          CDPPAStatus === "DISCARDED" ||
          CDPPAStatus === "VERIFICATION_ERROR",
        "fa fa-question-circle":
          CDPPAStatus === "PENDING" ||
          CDPPAStatus === "MANUAL_REVIEW" ||
          CDPPAStatus === "BLOCKED",
        [`status_${CDPPAStatus}`]: true,
      };
    },
    reviewerEmail(): string {
      if (
        this.lastVersionDocumentProcessProps?.reviewer_user?.email?.includes(
          "siltapp"
        )
      )
        return "Anonymous";
      return (
        this.lastVersionDocumentProcessProps?.reviewer_user?.email?.toLowerCase() ||
        ""
      );
    },
  },
  methods: {
    async toggleLock() {
      this.lockedByCurrentUser
        ? await this.unlockInvoiceDocument()
        : await this.lockInvoiceDocument();
      this.$emit("getCDPDocumentDetail", this.lastInvoiceDocumentFromDB.id);
    },
    async lockInvoiceDocument() {
      if (!this.isLastVersion) return;
      try {
        this.loadingLock = true;
        await CDPService.lockInvoiceDocument(
          this.$store.state.verificationFlow.app_id,
          this.lastInvoiceDocumentFromDB.id
        );
        window.addEventListener("beforeunload", handleBeforeUnload);
      } finally {
        this.loadingLock = false;
      }
    },
    async unlockInvoiceDocument() {
      try {
        this.loadingLock = true;
        await CDPService.unlockInvoiceDocument(
          this.$store.state.verificationFlow.app_id,
          this.lastInvoiceDocumentFromDB.id
        );
      } finally {
        this.loadingLock = false;
      }
    },
    updateLocalInvoiceDocumentVersionAndGetDifferences(
      event: CdpDefaultSkeleton
    ) {
      this.localInvoiceDocumentVersion = event;
      const auxInvoiceDocument = this.parseCDPDocumentFromFullSchemaToKeyValue(
        this.lastInvoiceDocumentFromDB
      );
      const auxLocalInvoiceDocument =
        this.parseCDPDocumentFromFullSchemaToKeyValue(
          this.localInvoiceDocumentVersion,
          true
        );
      if (
        JSON.stringify(auxLocalInvoiceDocument) !==
        JSON.stringify(auxInvoiceDocument)
      )
        this.showDifferences = true;
      else this.showDifferences = false;
    },
    parseDataFromFullSchemaToKeyValue(
      object: any,
      convertPercentage: boolean = false
    ) {
      const transform = (obj: any): any => {
        if (Array.isArray(obj)) {
          return obj.map((item) => transform(item));
        } else if (obj && typeof obj === "object") {
          const newObj: any = {};
          Object.keys(obj).forEach((key) => {
            if (Array.isArray(obj[key])) {
              newObj[key] = parseDataToKeyValueAndConvertPercentages(
                obj[key],
                convertPercentage
              );
            } else if (
              obj[key] &&
              typeof obj[key] === "object" &&
              "value" in obj[key]
            ) {
              newObj[key] = obj[key].value;
            } else if (obj[key] && typeof obj[key] === "object") {
              newObj[key] = transform(obj[key]);
            } else {
              newObj[key] = obj[key];
            }
          });
          return newObj;
        }
        return obj;
      };

      return transform(object);
    },
    parseCDPDocumentFromFullSchemaToKeyValue(
      activeInvoiceDocument: CdpDefaultSkeleton,
      convertPercentage: boolean = false
    ) {
      const auxInvoiceDocument = JSON.parse(
        JSON.stringify(activeInvoiceDocument)
      );
      auxInvoiceDocument.data = this.parseDataFromFullSchemaToKeyValue(
        auxInvoiceDocument.data,
        convertPercentage
      );
      return auxInvoiceDocument;
    },
  },
});
