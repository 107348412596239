











































































import Vue from "vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import {
  fieldOptions,
  LineItemKeys,
  sectionValue,
  tableValues,
} from "../models/cdp/cdpModels";
import PADetailCDPTable from "./components/PADetailCDPTable.vue";
import PADetailCDPInput from "./components/PADetailCDPInput.vue";

const VIEW_ALL_KEY = "documents_details_line_items_view_all";

export default Vue.extend({
  name: "PADetailCDPSectionDropDown",
  components: { VTab, PADetailCDPTable, PADetailCDPInput },
  props: {
    sectionName: { required: true, type: String },
    values: {
      required: true,
      type: Array as () => Array<sectionValue>,
    },
    tableValues: {
      required: true,
      type: Object as () => tableValues,
    },
    showDifferences: { required: true, type: Boolean },
    focusSectionName: { required: true, type: String },
    activeInput: { required: true, type: String },
    showChangeLog: { required: true, type: Boolean },
    isLastVersionAndNotLocked: { required: true, type: Boolean },
    activeUpdatedDataFileds: {
      required: true,
      type: Array as () => Array<string>,
    },
    commentKeys: { required: true, type: Array as () => Array<string> },
    mandatoryFieldsMissing: { required: true, type: Boolean },
  },
  watch: {
    focusSectionName() {
      if (this.focusSectionName && this.focusSectionName !== this.sectionName)
        this.activeSection = false;
      if (!this.activeSection && this.focusSectionName === this.sectionName)
        this.activeSection = true;
    },
  },
  data() {
    return {
      activeSection: true,
      lineItemsTabs: [
        "documents_details_line_items_view_all",
        "documents_details_line_items_product_details",
        "documents_details_line_items_quantity",
        "documents_details_line_items_unitary_price",
        "documents_details_line_items_discounts_and_fees",
        "documents_details_line_items_tax",
        "documents_details_line_items_total_amount",
      ] as (LineItemKeys | typeof VIEW_ALL_KEY)[],
      activeLineItemsTabs: ["documents_details_line_items_view_all"] as (
        | LineItemKeys
        | typeof VIEW_ALL_KEY
      )[],
    };
  },
  computed: {
    disabledDocumentSubtype(): boolean {
      return this.values.some(
        ({ key, value }: sectionValue) =>
          key === "data.document_type.value" &&
          value?.toLowerCase() !== "invoice"
      );
    },
    fieldOptions(): fieldOptions {
      const {
        activeUpdatedDataFileds,
        showChangeLog,
        showDifferences,
        isLastVersionAndNotLocked,
        commentKeys,
        activeInput,
      } = this;
      return {
        activeUpdatedDataFileds,
        showChangeLog,
        showDifferences,
        isLastVersionAndNotLocked,
        commentKeys,
        activeInput,
      };
    },
  },
  methods: {
    updateTabsSelected(tabClicked: LineItemKeys | typeof VIEW_ALL_KEY) {
      const index = this.activeLineItemsTabs.indexOf(
        "documents_details_line_items_view_all"
      );
      const tabIndex = this.lineItemsTabs.indexOf(tabClicked);
      if (index !== -1) this.activeLineItemsTabs.splice(index, 1);
      if (tabClicked === "documents_details_line_items_view_all")
        this.activeLineItemsTabs = [tabClicked];
      else if (this.activeLineItemsTabs.includes(tabClicked))
        this.activeLineItemsTabs = this.activeLineItemsTabs.filter(
          (tab) => tab !== tabClicked
        );
      else {
        let insertIndex = 0;
        for (let i = 0; i < this.activeLineItemsTabs.length; i++) {
          const activeTabIndex = this.lineItemsTabs.indexOf(
            this.activeLineItemsTabs[i]
          );
          if (activeTabIndex < tabIndex) {
            insertIndex = i + 1;
          }
        }
        this.activeLineItemsTabs.splice(insertIndex, 0, tabClicked);
      }
      if (!this.activeLineItemsTabs.length)
        this.activeLineItemsTabs.push("documents_details_line_items_view_all");
    },
    trackEventAndEmit() {
      this.$emit("onFocusSection", this.sectionName);
      this.$root.$emit(
        "analytics",
        "PADetailCDPSectionDropDown - Focus Section Clicked"
      );
    },
    getFieldType(input: sectionValue): string {
      if (input.isDate) return "DATE";
      if (!!input.options) return "SELECT";
      if (
        this.sectionName === "documents_details_totals_title" ||
        input.key === "data.payment_due_amount.value"
      )
        return "NUMBER";
      return "TEXT";
    },
  },
});
