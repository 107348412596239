


































































































import Vue from "vue";
import VPlaceholder from "@/components/vuemarc-ui-kit/placeholder/VPlaceholder.vue";

export interface Admin {
  email: string;
  role?: string | null;
  id?: string;
}

export default Vue.extend({
  name: "AdminDetail",
  props: {
    admin: { required: true, type: Object as () => Admin },
    adminRoleOptions: { required: true, type: Array as () => Admin[] },
    loadingProp: { required: false, type: Boolean, default: false },
    isRemovable: { required: true, type: Boolean },
    showSaveButton: { required: false, type: Boolean, default: true },
  },
  components: { VPlaceholder },
  watch: {
    admin: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.admin.role) return;
        if (this.oldAdminRole !== this.admin.role) this.editAdminRole = true;
        else this.editAdminRole = false;
      },
    },
  },
  data() {
    return {
      loading: false,
      confirmDelete: false,
      editAdminRole: false,
      cancelButtonClicked: false,
      errorFeedback: "",
      oldAdminRole: this.admin.role ? this.admin.role : "",
    };
  },
  methods: {},
});
