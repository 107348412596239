var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"main-nav"},[_c('div',[_c('img',{staticClass:"main-nav__logo",attrs:{"alt":"Silt logo","src":require("@/assets/img/logos/logo_silt_black.svg")}}),_c('div',{staticClass:"icon-hamburger",class:{ active: _vm.mobileMenuActive },on:{"click":function($event){return _vm.toggleMobileMenu()}}},[_c('div',{staticClass:"bar"}),_c('div',{staticClass:"bar"}),_c('div',{staticClass:"bar"})])]),_c('div',{staticClass:"main-nav__container",class:{ active: _vm.mobileMenuActive }},[(
        _vm.$store.state.company &&
        _vm.$store.state.company.plan_name === 'free_tier' &&
        !_vm.$store.state.loading
      )?_c('div',{staticClass:"banner-info small warning"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("free_tier_warning"))+" "),_c('router-link',{staticClass:"link nav-link",attrs:{"to":{ name: 'settings' }}},[_vm._v(" "+_vm._s(_vm.$t("banner_more_info"))+" ")])],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.loading),expression:"!$store.state.loading"}],staticClass:"main-nav__body"},[_c('div',{staticClass:"main-nav__container-selects-wrapper"},[(_vm.companiesOptions.length > 2)?_c('v-select-search',{staticClass:"select-search__white",attrs:{"label":_vm.$t('select_company'),"options":_vm.companiesOptions,"clip":15,"popoverPlacement":"top-start"},model:{value:(_vm.selectedCompanyId),callback:function ($$v) {_vm.selectedCompanyId=$$v},expression:"selectedCompanyId"}}):_vm._e(),(
            _vm.companyAppsFilteredByCompany.length > 1 ||
            _vm.$store.state.company === 'all-companies'
          )?_c('v-select-search',{staticClass:"select-search__white",class:{ disabled: _vm.$store.state.company === 'all-companies' },attrs:{"label":_vm.$t('select_verification_flow'),"options":_vm.companyAppsFilteredByCompany &&
            _vm.companyAppsFilteredByCompany.length
              ? _vm.companyAppsFilteredByCompany
              : _vm.companyAppsIdOptions,"clip":15,"popoverPlacement":"top-start"},model:{value:(_vm.selectedCompanyAppId),callback:function ($$v) {_vm.selectedCompanyAppId=$$v},expression:"selectedCompanyAppId"}}):(
            _vm.companyAppsFilteredByCompany &&
            _vm.companyAppsFilteredByCompany.length
          )?_c('div',{staticClass:"verification-flow-name__wrapper"},[_c('span',{staticClass:"hint"},[_vm._v(_vm._s(_vm.$t("verification_flow_name")))]),_c('span',[_vm._v(_vm._s(_vm.companyAppsFilteredByCompany[0].text))])]):_vm._e(),(
            _vm.$store.state.userRole !== 'REVIEWER' &&
            _vm.$store.state.verificationFlow.app_id
          )?_c('QuickActions',{staticClass:"main-nav__container-selects-wrapper-quick-actions-button-wrapper"}):_vm._e()],1),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'home' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'Overview Clicked')}}},[_c('i',{staticClass:"fad fa-home-lg-alt"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_overview")))])]),(_vm.$store.state.company !== 'all-companies')?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'analytics' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'Analytics Clicked')}}},[_c('i',{staticClass:"fad fa-analytics"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_analytics")))])]):_vm._e(),(
          _vm.$store.state.companyAppId != 'all' &&
          _vm.$store.state.verificationFlow &&
          _vm.$store.state.verificationFlow.flow_type === 'UDV'
        )?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'users' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'Users Clicked')}}},[_c('i',{staticClass:"fad fa-users"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_users")))])]):_vm._e(),(
          _vm.$store.state.companyAppId != 'all' &&
          _vm.$store.state.verificationFlow &&
          _vm.$store.state.verificationFlow.flow_type === 'KYB'
        )?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'companies' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'Companies Clicked')}}},[_c('i',{staticClass:"fad fa-building"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_companies")))])]):_vm._e(),(
          _vm.$store.state.companyAppId != 'all' &&
          _vm.$store.state.verificationFlow &&
          _vm.$store.state.verificationFlow.flow_type === 'CDP'
        )?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'complex-document-processor' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'CDP List Clicked')}}},[_c('i',{staticClass:"fad fa-file-invoice"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_cdp")))])]):_vm._e(),(_vm.$store.state.userIsStaff)?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'processing-attempts' }}},[_c('i',{staticClass:"fad fa-microchip"}),_c('span',[_vm._v("Processing Attempts")])]):_vm._e(),(
          _vm.$store.state.companyAppId !== 'all' &&
          _vm.$store.state.verificationFlow.flow_type !== 'CDP'
        )?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'misconduct-list' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'MisconductList Clicked')}}},[_c('i',{staticClass:"fad fa-badge-sheriff"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_misconduct_list")))])]):_vm._e(),(
          _vm.$store.state.companyAppId !== 'all' &&
          _vm.$store.state.verificationFlow.flow_type !== 'CDP'
        )?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'rules' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'RulesList Clicked')}}},[_c('i',{staticClass:"fad fa-code-branch fa-rotate-90-custom"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_rules")))])]):_vm._e(),_c('hr'),(_vm.$store.state.companyAppId != 'all')?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'verification-flow-settings' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'VerificationFlowSettings Clicked')}}},[_c('i',{staticClass:"fad fa-cog"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_verification_flow_link_&_settings")))])]):_vm._e()],1),_c('hr'),_c('div',{staticClass:"main-nav__footer"},[(
          _vm.$store.state.company &&
          _vm.$store.state.company !== 'all-companies' &&
          (_vm.$store.state.userRole === 'OWNER' || _vm.$store.state.userIsStaff)
        )?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'settings' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'CompanySettings Clicked')}}},[_c('i',{staticClass:"fad fa-cog"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_company_settings")))])]):_vm._e(),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'contact' }},nativeOn:{"click":function($event){return _vm.$root.$emit('analytics', 'ContactUs Clicked')}}},[_c('i',{staticClass:"fad fa-at"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_contact_us")))])]),_c('a',{staticClass:"nav-link",on:{"click":function($event){return _vm.logout()}}},[_c('i',{staticClass:"fad fa-sign-out"}),_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_log_out")))])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }