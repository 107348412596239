














































import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AdminDetail, { Admin } from "./components/AdminDetail.vue";
import { emailErrors } from "@/lib/validators";

export default Vue.extend({
  name: "AdminsTable",
  components: { AdminDetail },
  props: {
    adminsList: {
      required: true,
      type: Array as () => Array<Admin | undefined>,
      default: () => [],
    },
    comesFromCompanySettings: {
      required: false,
      type: Boolean,
      default: false,
    },
    showSaveButton: { required: false, type: Boolean, default: true },
  },
  watch: {
    "newUser.email"() {
      this.emailErrors = emailErrors(this.newUser.email, this);
    },
    admins: {
      immediate: true,
      deep: true,
      handler() {
        if (JSON.stringify(this.adminsList) !== JSON.stringify(this.admins))
          this.$emit("updateAdminsInfo", this.admins);
      },
    },
  },
  data() {
    return {
      admins: JSON.parse(JSON.stringify(this.adminsList)) as Array<Admin>,
      newUser: {
        email: "",
        ...(this.comesFromCompanySettings ? {} : { role: "REVIEWER" }),
      } as Admin,
      adminRoleOptions: [
        {
          value: "REVIEWER",
          text: this.$t("reviewer"),
        },
        {
          value: "ADMIN",
          text: this.$t("admin"),
        },
      ],
      feedbackError: "" as TranslateResult,
      emailErrors: "" as string | null,
    };
  },
  methods: {
    async addUser() {
      if (!this.newUser.email) {
        this.feedbackError = this.$t("log_in_password_forgotten_invalid_email");
        return;
      }
      const adminExists = this.admins.find(
        (admin: any) => admin.email === this.newUser.email
      );
      if (adminExists) {
        this.feedbackError = this.$t("admin_already_exists", {
          adminRole: adminExists.role,
        });
        return;
      }
      if (this.emailErrors) {
        this.feedbackError = this.emailErrors;
        return;
      }
      const newUserUpdated = {
        ...this.newUser,
        email: this.newUser.email.toLowerCase(),
      };
      this.admins.push(newUserUpdated);
      this.feedbackError = "";

      this.newUser.email = "";
      if (!this.comesFromCompanySettings) this.newUser.role = "REVIEWER";
    },
    updateAdminRole(admin: Admin) {
      const editedAdmin = this.admins.findIndex(
        (obj: any) => obj.id === admin.id
      );
      this.admins[editedAdmin].role = admin.role;
    },
    resetOldRole(admin: Admin) {
      if (this.comesFromCompanySettings) return;
      const oldAdmin = this.$store.state.verificationFlow.admins.find(
        (oldAdmin: Admin) => oldAdmin.id === admin.id
      );
      for (let i = 0; i < this.admins.length; i++) {
        if (this.admins[i].id === oldAdmin.id) {
          this.admins[i].role = oldAdmin.role;
          break;
        }
      }
    },
  },
});
