














































































































import Vue from "vue";
import KYBExtraDocumentsListTabs from "./components/KYBExtraDocumentsListTabs.vue";
import KYBExtraDocumentsPDFDetail from "./components/KYBExtraDocumentsPDFDetail.vue";
import KYBExtraDocumentsCompanySummary from "./components/KYBExtraDocumentsCompanySummary.vue";
import KYBService from "@/api/kyb/kybService";
import VInputFile from "../vuemarc-ui-kit/inputs/VInputFile.vue";
import KYBDeleteExtraDocumentModal from "./components/KYBDeleteExtraDocumentModal.vue";
import KYBDashboardUploadModal from "./components/KYBDashboardUploadModal.vue";
import eventBus from "@/lib/utils";

export default Vue.extend({
  components: {
    KYBExtraDocumentsListTabs,
    KYBExtraDocumentsPDFDetail,
    VInputFile,
    KYBExtraDocumentsCompanySummary,
    KYBDeleteExtraDocumentModal,
    KYBDashboardUploadModal,
  },
  name: "KYBExtraDocuments",
  props: {
    extraDocuments: { required: true, type: Array as () => Array<any> },
    KYBCompanyDetail: { required: true, type: Object },
    loadingProp: { required: true, type: Boolean },
  },
  data() {
    return {
      extraDocumentsFiltered: [] as any,
      extraDocumentSelected: {} as any,
      companyHasExtraDocuments:
        this.$store.state.verificationFlow &&
        this.$store.state.verificationFlow.extra_documents &&
        !!this.$store.state.verificationFlow.extra_documents.length,
      loading: false,
      activeTabName: "perDocument",
      showUploadDocumentModal: false,
      showDeleteExtraDocumentModal: false,
      showPDF: false,
    };
  },
  watch: {
    extraDocuments: {
      handler() {
        this.extraDocumentSelected = {};
        this.filterUniqueExtraDocuments();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getS3UrlsForId(extraDocumentEvent: any) {
      const s3Urls = this.extraDocuments
        .filter(
          (extraDocument: any) =>
            extraDocument.dynamic_file_type.id ===
            extraDocumentEvent.dynamic_file_type.id
        )
        .map((extraDocument: any) => extraDocument.s3_url);

      const displayName = this.extraDocuments
        .filter(
          (extraDocument: any) =>
            extraDocument.dynamic_file_type.id ===
            extraDocumentEvent.dynamic_file_type.id
        )
        .map((extraDocument: any) => extraDocument.display_name);

      const extraDocumentIds = this.extraDocuments
        .filter(
          (extraDocument: any) =>
            extraDocument.dynamic_file_type.id ===
            extraDocumentEvent.dynamic_file_type.id
        )
        .map((extraDocument: any) => extraDocument.id);

      return {
        dynamicFileTypeName: extraDocumentEvent.dynamic_file_type.name,
        type_id: extraDocumentEvent.dynamic_file_type.id,
        s3_urls: s3Urls,
        display_names: displayName,
        extraDocumentIds: extraDocumentIds,
      };
    },
    filterUniqueExtraDocuments() {
      const uniqueItems = new Map();
      this.extraDocumentsFiltered = this.extraDocuments
        .map((extraDocument: any) => this.getS3UrlsForId(extraDocument))
        .filter((extraDocument: any) => {
          const id = extraDocument.type_id;
          if (id && !uniqueItems.has(id)) {
            uniqueItems.set(id, extraDocument);
            return true;
          }
          return false;
        });
    },
    async processExtraDocuments(extraDocuments: any[]) {
      const extraDocumentsFiles = {
        files: extraDocuments || [],
      };

      await KYBService.postKYBCompanyExtraDocuments(
        this.KYBCompanyDetail.id,
        extraDocumentsFiles
      );

      this.$emit("getKYBCompanyDetail");
    },
  },
});
