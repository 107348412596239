import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getCookie, setCookie } from "@/lib/http/cookie";
import { store, State } from "@/state/store";
import eventBus from "@/lib/utils";

export const API_URI =
  process.env.VUE_APP_SILT_URL || "http://192.168.1.42:8000/";

export const HTTP = axios.create({
  baseURL: API_URI,
  headers: {
    "Content-Type": "application/json",
  },
});

let controller = new AbortController();

export const HTTPAbortable = async <T>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  try {
    return await HTTP({
      ...config,
      signal: controller.signal,
    });
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request was aborted.");
    } else {
      console.error("HTTP error:", error);
    }
    throw error;
  }
};

export const HTTPAbortableAbort = () => {
  controller.abort();
  controller = new AbortController();
};

// Todo: Implement interceptor and erase the router afterEach req
HTTP.interceptors.response.use(undefined, (error: any) => {
  if (error?.code !== "ERR_CANCELED") {
    let errorMessage = error.response?.data?.message || error.response?.data?.error || "unsucessful_feedback";

    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    if (htmlRegex.test(errorMessage)) {
      errorMessage = "unsucessful_feedback";
    }

    eventBus.$emit("v-toast", {
      localizedKey: errorMessage,
      type: "error-toast",
    });
  }
  

  if (error.config && error.response && error.response.status === 401) {
    setCookie("token", "", 0);
    // if (router.currentRoute.path !== "/login") router.push("/login");
    return Promise.reject(error);
  } else if (
    error.config &&
    error.response &&
    error.response.status !== 404 && error.response.status !== 500
  ) {
    return axios.request(error.config);
  } else if (error.message.indexOf("Network Error") > -1) {
    //if (router.currentRoute.path !== "/login") router.push("/login");
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

export interface QueryParams {
  query?: string;
  ending_before?: string;
  limit?: number;
  sortBy?: string;
  ascending?: number;
  last?: number;
}

//import { VueInstance } from "@/main";

export function getToken() {
  return getCookie("token");
}

export function setToken(token: string, expires_in: number = 36000) {
  HTTP.defaults.headers.Authorization = `Bearer ${token}`;
  setCookie("token", token, expires_in / 86400); // seconds to days
  setCompanyAppHeader();
  return token;
}

export async function removeToken() {
  HTTP.defaults.headers.Authorization = ``;
  // window.localStorage.clear()
  // window.sessionStorage.clear()
  setCookie("token", "", -1);
  return Promise.resolve(true);
}

export function setCompanyAppHeader() {
  let companyAppId = (store.state as State).companyAppId;
  if (companyAppId === "all") {
    delete HTTP.defaults.headers["X-Company-App-Id"];
  } else if (companyAppId) {
    HTTP.defaults.headers["X-Company-App-Id"] = companyAppId;
  }
}

// INIT: Set Authorization token if exists
if (getToken()) HTTP.defaults.headers.Authorization = `Bearer ${getToken()}`;
export interface QueryParams {
  query?: string;
  ending_before?: string;
  limit?: number;
  sortBy?: string;
  ascending?: number;
  last?: number;
}

// HTTP.interceptors.response.use(
//   function(response) {
//     // Do something with response data
//     return response;
//   },
//   function(error) {
//     VueInstance.$emit("v-flash-notice", { type: "error", text: error.message });
//     return Promise.reject(error);
//   }
// );

// TODO: Implement interceptor and erase the router afterEach req
// HTTP.interceptors.response.use(undefined, (error) => {
//   if (error.config && error.response && error.response.status === 401) {
//     setCookie('token', '', 0);
//     router.push('/login');
//     return Promise.reject(error);
//   } else if (error.config && error.response && (error.response.status !== 404
// || error.response.status !== 500)) {
//     return axios.request(error.config);
//   } else if ((error.message).indexOf('Network Error') > -1) {
//     router.push('/login');
//     return Promise.reject(error);
//   }
//   return Promise.reject(error);
// });

// static async setToken(token: string) {
//   HTTP.defaults.headers.Authorization = `Bearer ${token}`;
//   setCookie("token", token, COOKIE_DAYS_EXPIRATION); // Update cookie expiry date
// }
