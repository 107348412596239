var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"admin__container"},[_c('td',{staticClass:"admin_name__container"},[(_vm.loadingProp)?_c('VPlaceholder',{attrs:{"height":"20px","width":"200px"}}):_c('span',{staticClass:"admin__name"},[_vm._v(_vm._s(_vm.admin.email))])],1),_c('td',{staticClass:"admin_role__container"},[(
        _vm.$store.state.userRole === 'REVIEWER' ||
        _vm.admin.role === 'OWNER' ||
        !_vm.admin.role
      )?[_c('span',{staticClass:"admin__role capitalized"},[_vm._v(" "+_vm._s(_vm.admin.role ? _vm.$t(_vm.admin.role.toLowerCase()) : _vm.$t("owner"))+" "),(_vm.$store.state.user.email === _vm.admin.email)?_c('span',[_vm._v(" ("+_vm._s(_vm.$t("you"))+") ")]):_vm._e()])]:_c('v-select',{staticClass:"admin-role-v-select",attrs:{"options":_vm.adminRoleOptions,"inputClass":"v-input__slot__input__no-border"},model:{value:(_vm.admin.role),callback:function ($$v) {_vm.$set(_vm.admin, "role", $$v)},expression:"admin.role"}})],2),_c('td',[(
        _vm.editAdminRole === false &&
        _vm.$store.state.userRole !== 'REVIEWER' &&
        _vm.isRemovable &&
        _vm.admin.role !== 'OWNER' &&
        _vm.$store.state.user.email !== _vm.admin.email
      )?_c('v-button',{staticClass:"v-icon small admin__icon",class:{ error: _vm.confirmDelete },attrs:{"flat":"","icon":""},nativeOn:{"click":function($event){_vm.confirmDelete = !_vm.confirmDelete}}},[_c('i',{staticClass:"fad fa-trash"})]):_vm._e(),(_vm.errorFeedback)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errorFeedback))]):_vm._e()],1),_c('td',[_c('TransitionGroup',{staticClass:"confirm-buttons",style:({ '--total': 2 }),attrs:{"name":"offsetX"}},[(_vm.editAdminRole && _vm.showSaveButton)?_c('v-button',{key:'confirm',staticClass:"small",style:({ '--i': 1 }),attrs:{"loading":_vm.loading},nativeOn:{"click":function($event){_vm.$emit('updateAdminRole', _vm.admin);
          _vm.editAdminRole = false;}}},[_vm._v(" "+_vm._s(_vm.$t("save_changes_button"))+" ")]):_vm._e(),(_vm.confirmDelete)?_c('v-button',{key:'confirm',staticClass:"small error transparent",style:({ '--i': 1 }),attrs:{"loading":_vm.loading},nativeOn:{"click":function($event){_vm.$emit('deleteAdmin', _vm.admin);
          _vm.confirmDelete = false;}}},[_vm._v(" "+_vm._s(_vm.$t("confirm_button"))+" ")]):_vm._e(),(_vm.editAdminRole || _vm.confirmDelete)?_c('v-button',{key:'cancel',staticClass:"small transparent",style:({ '--i': 2 }),nativeOn:{"click":function($event){_vm.$emit('cancel', _vm.admin);
          _vm.confirmDelete = false;
          _vm.editAdminRole = false;}}},[_vm._v(" "+_vm._s(_vm.$t("cancel_button"))+" ")]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }