











































import Vue from "vue";

interface IUserDetail {
  public_id?: string;
  user: {
    id: string;
  };
  id: string;
  processing_attempts?: Array<any>;
  first_name: string;
  last_name: string;
  email: string;
  status: string;
}

export default Vue.extend({
  name: "ListModalHeader",
  props: {
    users: { required: false, type: Array as () => Array<IUserDetail> },
    userDetail: { required: false, type: Object as () => IUserDetail },
    resourceDetail: { required: true, type: Object },
    processingAttempts: { required: false, type: Array as () => Array<any> },
  },
  data() {
    return {
      nextUser: undefined as IUserDetail | undefined | string,
      previousUser: undefined as IUserDetail | undefined | string,
    };
  },
  methods: {
    getUserNameFromUser(): string {
      if (this.userDetail && this.userDetail.processing_attempts) {
        for (const pa of this.userDetail.processing_attempts) {
          for (const cm_exec of pa.core_module_executions) {
            if (
              cm_exec.type === "OCR" &&
              cm_exec.output &&
              cm_exec.output.name
            ) {
              const name = cm_exec.output.name + " " + cm_exec.output.surname;
              return name
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                .join(" ");
            }
          }
        }
      }
      return "";
    },

    getNextAndPreviousUser() {
      if (this.processingAttempts) {
        for (let i = 0; i < this.processingAttempts.length; i++) {
          if (
            this.processingAttempts[i].id === this.resourceDetail.id ||
            this.processingAttempts[i].id ===
              this.resourceDetail.processing_attempt_id
          ) {
            if (
              this.resourceDetail.id !==
                this.processingAttempts[this.processingAttempts.length - 1]
                  .id ||
              this.resourceDetail.processing_attempt_id !==
                this.processingAttempts[this.processingAttempts.length - 1].id
            )
              this.nextUser = this.processingAttempts[i + 1];
            if (
              this.resourceDetail.id ||
              this.resourceDetail.processing_attempt_id !==
                this.processingAttempts[0].id
            )
              this.previousUser = this.processingAttempts[i - 1];
          }
        }
        return;
      }
      if (this.userDetail)
        if (
          this.userDetail.public_id ===
            this.users[this.users.length - 1].public_id ||
          this.userDetail.id === this.users[this.users.length - 1].public_id
        ) {
          this.$emit("loadMoreUsers");
        }
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i] && this.users[i].public_id) {
          if (
            this.users[i].public_id === this.userDetail.public_id ||
            this.users[i].public_id === this.userDetail.id
          ) {
            this.nextUser = this.users[i + 1];
            if (this.userDetail.public_id !== this.users[0].public_id)
              this.previousUser = this.users[i - 1];
          }
        }
        // TODO: NextUser must always have the same type value, either an ID or a User object
        if (this.users[i].user && this.users[i].user.id)
          if (this.users[i].user.id === this.userDetail.id) {
            if (
              this.userDetail.id !== this.users[this.users.length - 1].user.id
            )
              this.nextUser = this.users[i + 1].user.id;
            if (this.userDetail.id !== this.users[0].user.id)
              this.previousUser = this.users[i - 1].user.id;
          }
      }
    },

    updateUserDetail(event: string) {
      this.getNextAndPreviousUser();
      if (event === "nextUser") {
        if (this.nextUser)
          this.$emit("onGoToPreviousOrNextUser", this.nextUser);
      } else {
        if (this.previousUser)
          this.$emit("onGoToPreviousOrNextUser", this.previousUser);
      }
    },
  },
  computed: {
    userName() {
      // Get Name from User
      if (
        this.userDetail &&
        (!!this.userDetail.first_name || !!this.userDetail.last_name)
      )
        return (
          this.userDetail.first_name?.toLowerCase() +
          " " +
          this.userDetail.last_name?.toLowerCase()
        );
      if (!this.resourceDetail || !this.userDetail) return "";

      // Get Name from Owner
      if (this.resourceDetail.owner_user) {
        let ownerName =
          this.resourceDetail.owner_user &&
          this.resourceDetail.owner_user.first_name
            ? `${this.resourceDetail.owner_user.first_name}`
            : "";
        if (this.resourceDetail.owner_user.last_name) {
          ownerName += ` ${this.resourceDetail.owner_user.last_name.toLowerCase()}`;
        }
        if (ownerName) return ownerName;
      }

      if (
        this.resourceDetail &&
        this.resourceDetail.owner_user &&
        this.resourceDetail.owner_user.email
      )
        return (
          this.resourceDetail &&
          this.resourceDetail.owner_user &&
          this.resourceDetail.owner_user.email
        );
      if (this.userDetail && this.userDetail.email)
        return this.userDetail && this.userDetail.email;
      else this.getUserNameFromUser;
    },
    statusClass() {
      const userStatus = this.userDetail && this.userDetail.status;
      const resourceStatus = this.resourceDetail && this.resourceDetail.status;

      return {
        "fa fa-check-circle":
          userStatus === "SUCCESS" || resourceStatus === "SUCCESS",
        "fa fa-times-circle":
          userStatus === "ERROR" || resourceStatus === "ERROR",
        "fa fa-question-circle":
          userStatus === "PENDING" ||
          resourceStatus === "PENDING" ||
          userStatus === "MANUAL_REVIEW" ||
          resourceStatus === "MANUAL_REVIEW",
        [`status_${userStatus || resourceStatus}`]: true,
        [resourceStatus === "PENDING"
          ? "IN_PROGRESS"
          : `status_${userStatus || resourceStatus}`]: true,
      };
    },
  },
  created() {
    document.addEventListener("keyup", (event) => {
      if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
        this.updateUserDetail("previousUser");
      } else if (event.key === "ArrowRight" || event.key === "ArrowDown") {
        this.updateUserDetail("nextUser");
      }
    });
  },
});
