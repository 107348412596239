var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasAdverseMediaRawData)?[_c('h2',[_vm._v(_vm._s(_vm.$t("adverse_media_detail_title")))]),_c('div',{class:['banner-info', _vm.bannerClass]},[_c('div',{staticClass:"banner-info__container"},[_c('div',{staticClass:"banner-info__container-info_wrapper"},[_c('i',{staticClass:"fad fa-info-circle"}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.bannerMessage))}})]),_c('div',{staticClass:"banner-info__container-last-uptaded_wrapper"},[_c('span',[_vm._v(_vm._s(_vm.$t("users_detail_verification_info_last_updated")))]),_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.adverseMedia.updated_at)))])])]),_c('div',{staticClass:"button-wrapper"},[(
            ['CONFIRMED', 'PENDING_MANUAL_REVIEW'].includes(
              _vm.adverseMedia.status
            )
          )?_c('v-button',{staticClass:"small transparent success",class:{
            primary: _vm.adverseMedia.status === 'PENDING_MANUAL_REVIEW',
          },nativeOn:{"click":function($event){return _vm.ignoreOrConfirmAdverseMedia('ignore')}}},[_vm._v(" "+_vm._s(_vm.$t("companies_detail_sanctions_check_dismiss_hit_button"))+" ")]):_vm._e(),(_vm.adverseMedia.status === 'PENDING_MANUAL_REVIEW')?_c('span',{staticClass:"button-spacing"},[_vm._v(" "+_vm._s(_vm.$t("or"))+" ")]):_vm._e(),(
            ['IGNORED', 'PENDING_MANUAL_REVIEW'].includes(_vm.adverseMedia.status)
          )?_c('v-button',{staticClass:"small transparent error",class:{
            primary: _vm.adverseMedia.status === 'PENDING_MANUAL_REVIEW',
          },nativeOn:{"click":function($event){return _vm.ignoreOrConfirmAdverseMedia('confirm')}}},[_vm._v(" "+_vm._s(_vm.$t("companies_detail_sanctions_check_acknowledge_hit_button"))+" ")]):_vm._e()],1)]),_c('h2',[_vm._v(_vm._s(_vm.$t("adverse_media_detail_summary")))]),_c('div',{staticClass:"adverse-media-raw-data-content"},_vm._l((_vm.adverseMedia.raw_data),function(adverseMediaHit){return _c('div',{key:adverseMediaHit.si_identifier,staticClass:"context"},[_c('div',{staticClass:"adverse-media-raw-data-content-list-wrapper__info"},[_c('ul',[_c('li',[_c('span',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t("adverse_media_detail_summary_title"))+" ")]),_c('span',[_vm._v(_vm._s(adverseMediaHit.title))])]),_c('li',[_c('span',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t("adverse_media_detail_summary_date"))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("date")(adverseMediaHit.published_at)))])]),_c('li',[_c('span',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t("companies_detail_sanctions_check_data_source"))+" ")]),_c('span',[_vm._v(_vm._s(adverseMediaHit.media_source.name || "-"))])]),_c('li',[_c('span',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t("adverse_media_detail_summary_see_more"))+" ")]),(adverseMediaHit.media_source.url || adverseMediaHit.url)?_c('a',{attrs:{"target":"_blank","href":adverseMediaHit.media_source.url || adverseMediaHit.url}},[_vm._v(" "+_vm._s(_vm.$t("adverse_media_detail_summary_read_full_article")))]):_c('span',[_vm._v("-")])])])])])}),0)]:_c('div',{staticClass:"context"},[_vm._v(" "+_vm._s(_vm.$t("adverse_media_detail_no_adverse_media"))+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }