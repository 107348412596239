





























































































import Vue from "vue";
import KYBService from "@/api/kyb/kybService";
import SuccessOverlay from "../SuccessOverlay.vue";
import KYBFormModalSearchCompany from "./components/KYBFormModalSearchCompany.vue";
import { TranslateResult } from "vue-i18n";
import eventBus from "@/lib/utils";

export default Vue.extend({
  components: { SuccessOverlay, KYBFormModalSearchCompany },
  name: "KYBFormModal",
  props: {
    showKYBFormModal: {
      required: true,
    },
  },
  data() {
    return {
      contactEmail: "",
      emailErrors: "" as TranslateResult,
      loading: false,
      kybCompanyId: "",
      activeKYBCompany: null as any,
      showSuccessOverlay: false,
    };
  },
  watch: {
    contactEmail(contactEmail) {
      this.emailHasErrors(contactEmail);
    },
  },
  methods: {
    onCloseKYBFormModal() {
      if (this.activeKYBCompany) this.deleteEmptyKYBCompany();
      this.redirectToCompaniesList();
      this.$emit("showKYBFormModal", false);
      this.emailErrors = "";
      this.contactEmail = "";
      this.showSuccessOverlay = false;
      this.kybCompanyId = "";
    },
    redirectToCompaniesList() {
      if (
        this.$router.currentRoute.name !== "companies" &&
        this.showSuccessOverlay
      )
        this.$router.push({ name: "companies" });
    },
    emailHasErrors(email: string): TranslateResult {
      if (
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) ||
        !email.length ||
        email.length < 10
      ) {
        this.emailErrors = "";
        return "";
      }
      this.emailErrors = this.$t("email_validator_error_feedback");
      return this.emailErrors;
    },
    async createKYBCompany() {
      this.loading = true;
      try {
        if (!this.emailErrors) {
          const res = await KYBService.createKYBCompany(
            this.$store.state.companyAppId,
            this.contactEmail ? this.contactEmail.toLowerCase() : null
          );

          this.kybCompanyId = res.data.id;

          if (this.contactEmail) {
            eventBus.$emit("v-toast", {
              localizedKey: "successful_mail_feedback",
              type: "success-toast",
            });
            this.showSuccessOverlay = true;
          }
        }
      } finally {
        this.loading = false;
        this.$root.$emit("analytics", "KYBCompany - Create KYBCompany Clicked");
      }
    },
    async patchKYBCompany() {
      try {
        this.loading = true;
        await KYBService.patchKYBCompany(this.kybCompanyId, {
          company_info_id: this.activeKYBCompany.id,
        });
        this.showSuccessOverlay = true;
        this.activeKYBCompany = null;
      } finally {
        this.loading = false;
        this.$root.$emit(
          "analytics",
          "KYBCompany - Set KYBCompany Details Clicked"
        );
      }
    },
    async deleteEmptyKYBCompany() {
      try {
        const res = await KYBService.deleteEmptyKYBCompany(this.kybCompanyId);
        this.$emit("showKYBFormModal");
        this.kybCompanyId = "";
        this.activeKYBCompany = null;
      } finally {
        this.$root.$emit("analytics", "KYBCompany - Delete KYBCompany Clicked");
      }
    },
  },
});
