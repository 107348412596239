import { render, staticRenderFns } from "./KYBModal.vue?vue&type=template&id=b19fd25e&scoped=true&"
import script from "./KYBModal.vue?vue&type=script&lang=ts&"
export * from "./KYBModal.vue?vue&type=script&lang=ts&"
import style0 from "./KYBModal.vue?vue&type=style&index=0&id=b19fd25e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b19fd25e",
  null
  
)

export default component.exports