
































































































































































import CompaniesService from "@/api/companies/companiesService";
import Vue from "vue";

export interface DocumentData {
  description: string;
  comments: string | null;
  id?: string;
  name: string;
  mandatory: boolean;
  multi_file: boolean;
}

export default Vue.extend({
  components: {},
  name: "KYBExtraDocumentForm",
  props: {
    extraDocumentProp: { required: true, type: Object as () => DocumentData },
  },
  data() {
    return {
      mandatory: false,
      multipleFiles: false,
      extraDocumentNameOptions: [] as DocumentData[],
      extraDocumentNameValue: "",
      extraDocumentDescription: "",
      loading: false,
      extraDocumentComment: "",
      addNewDocumentType: false,
      newDocumentTypeName: "",
      newDocumentTypeDescription: "",
      extraDocument: {
        description: "",
        comments: "",
        name: "",
        mandatory: false,
        multi_file: false,
      } as DocumentData,
      readable: false,
      create: false,
    };
  },
  computed: {
    filteredFileTypesOptions(): Array<DocumentData> {
      return this.extraDocumentNameOptions.map((option: DocumentData) => ({
        ...option,
        text: this.$t(option.name),
        value: option.name,
      }));
    },
    getExtraDocumentOptionsDescription(): string {
      const matchingOption = this.extraDocumentNameOptions.find(
        (extraDocumentOption: DocumentData) =>
          extraDocumentOption.name === this.extraDocument.name
      );

      return matchingOption ? matchingOption.description : "-";
    },
  },
  watch: {
    extraDocumentProp: {
      handler() {
        if (
          Object.keys(this.extraDocumentProp).length &&
          this.extraDocumentProp.name.length
        ) {
          this.extraDocument = this.extraDocumentProp;
          this.readable = true;
        } else this.create = true;
      },
      immediate: true,
    },
    extraDocumentNameValue() {
      this.extraDocument.name = this.extraDocumentNameValue;
    },
  },
  methods: {
    handleClick() {
      if (this.readable) {
        this.readable = false;
        this.addNewDocumentType = true;
      } else {
        for (const extraDocument of this.extraDocumentNameOptions) {
          if (this.extraDocument.name === extraDocument.name) {
            this.extraDocument.id = extraDocument.id;
          }
        }
        this.extraDocument.description = this.extraDocument.description
          ? this.extraDocument.description
          : this.getExtraDocumentOptionsDescription;
        this.$emit("onUpdateExtraDocumentData", this.extraDocument);
        this.readable = true;
      }
    },
    handleCancelButtonClick() {
      if (this.readable) {
        this.$emit("deleteExtraDocumentForm", this.extraDocument.id);
      } else if (this.create) {
        this.$emit("cancelExtraDocumentForm");
      } else {
        this.readable = true;
      }
    },
    async getOptionsOfFileTypes(fileTypesOptions: DocumentData[]) {
      this.extraDocumentNameOptions = fileTypesOptions;
      this.extraDocumentNameValue =
        this.extraDocumentNameOptions[0]?.name || "Name not provided";
    },
    async getCompanyAppFileTypes() {
      this.loading = true;
      try {
        const res = await CompaniesService.getFileTypes("KYB");
        this.getOptionsOfFileTypes(res.data.results);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    if (
      Object.keys(this.extraDocumentProp).length &&
      !this.extraDocumentProp.name.length
    )
      await this.getCompanyAppFileTypes();
  },
});
