<template>
  <div slot="body" class="details__wrapper">
    <div
      class="info__wrapper in-transition"
      :class="{ hidden: $refs.files && $refs.files.loadingFiles }"
    >
      <h3>{{ $t("users_detail_verification_info_title") }}</h3>
      <div v-if="!loading" class="info__container">
        <div v-if="resourceDetail.status" class="fields-group">
          <div>
            <div class="fields-group__header">{{ $t("status_title") }}</div>
            <v-select
              v-if="resourceDetail.status && !$store.getters.isReadOnlyReviewer"
              :class="`status small ${resourceDetail.status}`"
              :options="statusOptions"
              v-model="resourceDetail.status"
              @change="$emit('onUpdateResource', resourceDetail)"
            />
            <span v-else :class="`status_${resourceDetail.status}`">
              {{ resourceDetail.status | clean_cap }}
            </span>
          </div>
          <div>
            <div class="fields-group__header">
              {{ $t("users_detail_verification_info_face_verification") }}
            </div>
            <span :class="`status_${resourceDetail.verification_status}`">
              {{ resourceDetail.verification_status | clean_cap }}
            </span>
          </div>
          <div>
            <div class="fields-group__header">
              {{ $t("users_detail_verification_info_doc_verification") }}
            </div>
            <span :class="`status_${resourceDetail.ocr_status}`">
              {{ resourceDetail.ocr_status | clean_cap }}
            </span>
          </div>

          <div>
            <div class="fields-group__header">
              {{ $t("users_detail_verification_info_manual_review_status") }}
            </div>
            <span :class="`status_${resourceDetail.manual_review_status}`">
              {{ resourceDetail.manual_review_status | clean_cap }}
            </span>
          </div>
          <div v-if="resourceDetail.errors && resourceDetail.errors.length">
            <div class="fields-group__header">
              {{ $t("users_detail_checks_error_details") }}
            </div>
            <span
              class="ERROR error-details"
              v-for="(error, i) of resourceDetail.errors"
              :key="i"
            >
              {{ error ? $t(error.toLowerCase()) : error | clean_cap_error }}
            </span>
          </div>
          <div>
            <div class="fields-group__header">
              {{ $t("users_detail_verification_info_last_updated") }}
            </div>
            {{ resourceDetail.updated_at | dateTime }}
          </div>
        </div>
      </div>
      <VPlaceholder
        v-else
        class="info__container"
        height="300px"
        borderRadius="20px"
      />
      <h3>{{ $t("users_detail_security_checks_title") }}</h3>
      <div v-if="!loading" class="info__container extracted_info">
        <div class="fields-group">
          <div>
            <div class="fields-group__header">
              {{ $t("users_detail_security_checks_screen_detector") }}
            </div>
            <div
              v-if="
                filesPVErrors.has('E_FACE_SCREEN_RECAPTURE_SPOOF') ||
                filesPVErrors.has('E_ID_SPOOF')
              "
              :class="`status_ERROR`"
            >
              {{ $t("screen_recapture_spoof_error") }}
            </div>
            <div v-else :class="`status_SUCCESS`">
              {{ $t("screen_recapture_spoof_success") }}
            </div>
          </div>
          <div>
            <div class="fields-group__header">
              {{ $t("users_detail_security_checks_print_detector") }}
            </div>
            <div
              v-if="filesPVErrors.has('E_ID_PRINTED')"
              :class="`status_ERROR`"
            >
              {{ $t("printed_document_error") }}
            </div>
            <div v-else :class="`status_SUCCESS`">
              {{ $t("printed_document_success") }}
            </div>
          </div>
          <div>
            <div class="fields-group__header">
              {{ $t("users_detail_security_checks_picture_overlay_detector") }}
            </div>
            <div
              v-if="filesPVErrors.has('E_ID_OVERLAY')"
              :class="`status_ERROR`"
            >
              {{ $t("overlay_error") }}
            </div>
            <div v-else :class="`status_SUCCESS`">
              {{ $t("overlay_success") }}
            </div>
          </div>
          <div>
            <div class="fields-group__header">
              {{ $t("users_detail_security_checks_face_detector") }}
            </div>
            <div
              v-if="filesPVErrors.has('E_NO_FACE_FOUND')"
              :class="`status_ERROR`"
            >
              {{ $t("no_faces_found_error") }}
            </div>
            <div v-else :class="`status_SUCCESS`">
              {{ $t("no_faces_found_success") }}
            </div>
          </div>
          <div>
            <div class="fields-group__header">
              {{ $t("users_detail_security_checks_expected_document") }}
            </div>
            <div
              v-if="filesPVErrors.has('E_UNEXPECTED_DOCUMENT_TYPE')"
              :class="`status_ERROR`"
            >
              {{
                $t("unexpected_document_type_error", {
                  permissionType: $t(
                    resourceDetail.permission_type &&
                      resourceDetail.permission_type.toLowerCase()
                  ),
                })
              }}
            </div>
            <div v-else :class="`status_SUCCESS`">
              {{
                $t("unexpected_document_type_success", {
                  permissionType: $t(
                    resourceDetail.permission_type &&
                      resourceDetail.permission_type.toLowerCase()
                  ),
                })
              }}
            </div>
          </div>
        </div>
      </div>
      <VPlaceholder
        v-else
        class="info__container"
        height="300px"
        borderRadius="20px"
      />
      <div class="extracted-info__header__wrapper">
        <h3>{{ $t("users_detail_extracted_info") }}</h3>
        <div
          v-if="
            resourceDetail.identity_document &&
            !$store.getters.isReadOnlyReviewer
          "
        >
          <v-button
            v-if="!editing"
            class="appended-icon small transparent"
            @click.native="
              editing = true;
              $root.$emit(
                'analytics',
                'Users - Edit Identity Document Clicked'
              );
            "
          >
            <i class="fad fa-edit" />
            <span>
              {{
                $t("users_detail_extracted_info_edit_identity_document_button")
              }}
            </span>
          </v-button>
          <v-button
            v-else
            class="appended-icon small"
            @click.native="onSaveIdentityDocument()"
            :loading="loading"
          >
            <i class="fad fa-save" />
            <span>{{ $t("save_changes_button") }}</span>
          </v-button>
        </div>
      </div>
      <PADetailExtractedInfo
        v-if="resourceDetail.identity_document"
        :data="resourceDetail.identity_document"
        :status="resourceDetail.status"
        :permissionType="resourceDetail.permission_type"
        :editing="editing"
        type="info"
      />
      <VPlaceholder v-else-if="loading" height="300px" borderRadius="20px" />
      <PADetailExtractedInfo
        v-else
        :data="resourceDetail.ocr_data && resourceDetail.ocr_data.mrz"
        :status="resourceDetail.status"
        :permissionType="resourceDetail.permission_type"
        :editing="false"
        type="info"
      />
      <template
        v-if="
          resourceDetail.ocr_raw &&
          Object.keys(resourceDetail.ocr_raw).length > 0
        "
      >
        <h3 class="header--non-rellevant">
          {{ $t("users_detail_extracted_info") }} (RAW)
        </h3>
        <PADetailExtractedInfo
          :data="resourceDetail.ocr_raw"
          :status="resourceDetail.status"
          :permissionType="resourceDetail.permission_type"
          :displayNullValues="false"
          :editing="false"
          class="non-rellevant"
          type="raw"
        />
      </template>
    </div>
    <div>
      <h3>{{ $t("users_detail_files_title") }}</h3>
      <PADetailFilesCard
        v-if="!loading"
        ref="files"
        class="in-transition"
        :resourceDetail="resourceDetail"
      />
      <VPlaceholder v-else height="600px" borderRadius="20px" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  VerificationsMap,
  VERIFICATIONS_TYPES_MAP,
} from "@/models/verificationsTypes";
import PADetailFilesCard from "@/components/processing-attempts/pa-detail/components/components/PADetailFilesCard.vue";
import PADetailExtractedInfo from "@/components/processing-attempts/pa-detail/components/components/PADetailExtractedInfo.vue";
import VPlaceholder from "@/components/vuemarc-ui-kit/placeholder/VPlaceholder.vue";
import { handleBeforeUnload } from "@/lib/utils";

export default Vue.extend({
  name: "PADetailGenericDetail",
  components: { PADetailFilesCard, PADetailExtractedInfo, VPlaceholder },
  props: {
    resourceDetail: { required: true },
    statusOptions: { required: true },
    loading: { required: true },
  },
  data() {
    return {
      VerificationsMap,
      VERIFICATIONS_TYPES_MAP,
      editing: false,
    };
  },
  watch: {
    editing() {
      if (this.editing)
        window.addEventListener("beforeunload", handleBeforeUnload);
      else window.removeEventListener("beforeunload", handleBeforeUnload);
    },
  },
  computed: {
    filesPVErrors() {
      if (!this.loading || !this.$refs.files) return new Set();
      return this.$refs.files.pictureValidationErrors;
    },
  },
  methods: {
    async onSaveIdentityDocument() {
      this.$emit("onSaveEditIdentityForm");
      this.editing = false;

      this.$root.$emit("analytics", "Users - Edit Identity Document Saved");
    },
  },
});
</script>
<style lang="stylus">
@import '~@/styles/global/theme.styl';

.extracted-info__header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-md;

  h3 {
    margin-bottom: 0;
  }
}

.error-details {
  margin-right: 2px;
  margin-top: $spacing-sm;
}
</style>
