var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"user-status context"},[_c('KYBModalDetailHeader',{attrs:{"uboDetail":_vm.kybUserDetail}}),(_vm.showStatus)?_c('div',[_c('span',{staticClass:"field-label"},[_vm._v(_vm._s(_vm.$t("users_detail_user_status"))+" ")]),_c('span',{staticClass:"capitalized",class:_vm.kybUserDetail.status === 'PENDING'
            ? 'IN_PROGRESS'
            : ("status_" + (_vm.kybUserDetail.status))},[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm.kybUserDetail.status))+" ")])]):_vm._e(),_c('div',{staticClass:"user-status-information"},[_c('div',{staticClass:"user-status-information__details"},[_c('div',[_c('span',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_missing_verifications"))+" ")]),(
              !_vm.kybUserDetail.missing_verifications ||
              _vm.kybUserDetail.missing_verifications.length == 0
            )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("users_detail_missing_verifications_none"))+" ")]):_vm._e(),_c('oneline-verifications',{attrs:{"verifications":_vm.kybUserDetail.missing_verifications}})],1),_c('div',[(_vm.kybUserDetail.aml_info || _vm.kybUserDetail.pep_info)?_c('div',{staticClass:"aml-wrapper"},[_c('PADetailAmlPepSumup',{attrs:{"title":"AML","AmlInfo":_vm.kybUserDetail.aml_info,"AmlInfoUpdatedAt":_vm.kybUserDetail.aml_info_updated_at}}),_c('PADetailAmlPepSumup',{attrs:{"title":"PEP","AmlInfo":_vm.kybUserDetail.pep_info,"AmlInfoUpdatedAt":_vm.kybUserDetail.pep_info_updated_at}})],1):_vm._e()]),_c('div',[_c('span',{staticClass:"field-label"},[_vm._v(_vm._s(_vm.$t("name")))]),(
              _vm.kybUserDetail &&
              _vm.kybUserDetail.user &&
              !!_vm.kybUserDetail.user.first_name
            )?_c('span',{staticClass:"capitalized"},[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm.kybUserDetail.user.first_name))+" ")]):(_vm.kybUserDetail.name)?_c('span',{staticClass:"capitalized"},[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm.kybUserDetail.user.name))+" ")]):_vm._e(),(
              _vm.kybUserDetail &&
              _vm.kybUserDetail.user &&
              !!_vm.kybUserDetail.user.last_name
            )?_c('span',{staticClass:"capitalized"},[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm.kybUserDetail.user.last_name))+" ")]):_vm._e()]),(
            _vm.kybUserDetail && _vm.kybUserDetail.user && _vm.kybUserDetail.user.email
          )?_c('div',[_c('span',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t("email"))+": ")]),_c('span',[_vm._v(_vm._s(_vm.kybUserDetail.user.email))])]):_vm._e()]),_c('div',[(
            _vm.kybUserDetail.certificate && _vm.kybUserDetail.certificate.file_url
          )?_c('v-button',{staticClass:"small v-icon",attrs:{"flat":"","icon":""},nativeOn:{"click":function($event){return _vm.downloadCertificate(_vm.kybUserDetail.certificate.file_url)}}},[_c('i',{staticClass:"fad fa-file-certificate"})]):_vm._e(),_c('v-button',{staticClass:"small appended-icon transparent",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onOpenMisconductModal()}}},[_c('i',{staticClass:"fad fa-badge-sheriff"}),_c('span',[_vm._v(_vm._s(_vm.$t("users_detail_add_to_misconduct_list_button")))])])],1)])],1),_c('MisconductModalAdd',{attrs:{"misconductOptions":_vm.misconductOptions,"showMisconductDetail":_vm.showMisconductDetail,"user":_vm.kybUserDetail,"resourceDetail":_vm.kybUserDetail},on:{"showMisconductDetail":function($event){_vm.showMisconductDetail = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }