










































































































































import Vue from "vue";
import KYBIgnoreStatus from "./components/KYBIgnoreStatus.vue";
import KYBService from "@/api/kyb/kybService";
import { TranslateResult } from "vue-i18n";
import KYBIndividualEmailReminderButton from "./components/KYBIndividualEmailReminderButton.vue";
import eventBus from "@/lib/utils";

export default Vue.extend({
  components: { KYBIgnoreStatus, KYBIndividualEmailReminderButton },
  name: "KYBStatusInfo",
  props: {
    KYBCompanyDetail: { required: true, type: Object },
    comesFromParentKYBCompany: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showIgnoreModal: false,
      loading: false,
      contactEmail: "",
      showContactInput: false,
      emailErrors: "" as TranslateResult,
    };
  },
  watch: {
    contactEmail(contactEmail) {
      this.emailHasErrors(contactEmail);
    },
    KYBCompanyDetail: {
      handler() {},
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async ignoreKYBCompany() {
      try {
        this.loading = true;
        await KYBService.ignoreKYBCompany(this.KYBCompanyDetail.id);

        this.$emit("getKYBCompanyDetail");
      } finally {
        this.loading = false;
        this.showIgnoreModal = false;
      }
    },
    downloadCertificate(url: string) {
      window.open(url, "_blank");
    },
    async setContact() {
      try {
        this.loading = true;
        if (!this.emailErrors) {
          await KYBService.setContact(
            this.KYBCompanyDetail.id,
            this.contactEmail
          );

          eventBus.$emit("v-toast", {
            localizedKey: "successful_mail_feedback",
            type: "success-toast",
          });

          this.showContactInput = false;
          this.contactEmail = "";
          this.emailErrors = "";

          this.$emit("getKYBCompanyDetail");
        }
      } finally {
        this.loading = false;
        this.showIgnoreModal = false;
      }
    },
    emailHasErrors(email: string): TranslateResult {
      if (
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) ||
        !email.length ||
        email.length < 10
      ) {
        this.emailErrors = "";
        return "";
      }
      this.emailErrors = this.$t("email_validator_error_feedback");
      return this.emailErrors;
    },
  },
});
