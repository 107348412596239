















































import Vue from "vue";
import PADetailPOA from "../processing-attempts/pa-detail/components/PADetailPOA.vue";
import FilesService from "@/api/files/filesService";
import ProcessingAttemptsService from "@/api/processingAttempts/processingAttemptsService";
import TabAttempts from "../processing-attempts/pa-tabs/TabAttempts.vue";

export default Vue.extend({
  components: { PADetailPOA, TabAttempts },
  name: "KYBCPOADetail",
  props: {
    processingAttempts: { required: true, type: Array },
    expectedInfo: { required: true },
    kybCompanyId: { required: true, type: String },
  },
  data() {
    return {
      hasCPOAsProcessingAttepts: false,
      statusOptions: [
        { value: "SUCCESS" },
        { value: "ERROR" },
        { value: "PENDING" },
      ],
      loading: false,
      resourceDetail: {} as any,
      showDashboardUpload: false,
    };
  },
  watch: {
    processingAttempts: {
      handler() {
        this.hasCPOAsProcessingAttepts = !!this.processingAttempts.length;
        if (this.hasCPOAsProcessingAttepts)
          this.resourceDetail = this.processingAttempts[0];
      },
      immediate: true,
    },
  },
  methods: {
    updateResourceDetail(processingAttemptId: string) {
      this.resourceDetail = this.processingAttempts.find(
        (processingAttempt: any) =>
          processingAttempt.processing_attempt_id === processingAttemptId
      );
    },
    async postFile(file: any) {
      const data = {
        type: "poa_expense",
        file_name: file.name,
        file: file,
      };
      return await FilesService.postFile(data);
    },
    async postPOAProcessingAttempt(id: string) {
      this.loading = true;
      const parsedData = {
        files: [`${id}`],
        type: "CPOA",
        permission_type: "CPOA",
        data: { kyb_company_id: this.kybCompanyId },
      };
      try {
        const res = await ProcessingAttemptsService.postProcessingAttempt(
          parsedData
        );
        const processingAttemptUploaded =
          await ProcessingAttemptsService.pollProcessingAttemptStatus(
            res.data.processing_attempt_id
          );
        return processingAttemptUploaded;
      } finally {
        this.loading = false;
      }
    },
    async uploadFiles(file: any) {
      if (!Array.isArray(file)) file = [file.file];
      try {
        this.loading = true;

        const postFileResponse = await this.postFile(file[0]);
        await FilesService.getFile(postFileResponse.data.file_id);
        await this.postPOAProcessingAttempt(postFileResponse.data.file_id);
        this.$emit("getKYBCompanyDetail");
      } finally {
        this.loading = false;
      }
    },
  },
});
