var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"user-status"},[_c('div',{staticClass:"user-status__header"},[_c('span',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.hasUserStatus ? _vm.$t("users_detail_user_status") : "Processing Attempt status:")+" ")]),_c('span',{staticClass:"capitalized listed-item",class:_vm.hasUserStatus
            ? ("status_" + (_vm.user.status))
            : ("status_" + (_vm.resourceDetail.status))},[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm.hasUserStatus ? _vm.user.status : _vm.resourceDetail.status))+" ")]),(
          _vm.user.certificate &&
          _vm.user.certificate.file_url &&
          !_vm.$store.getters.isDesktop
        )?_c('v-button',{staticClass:"small v-icon",attrs:{"flat":"","icon":""},nativeOn:{"click":function($event){return _vm.downloadCertificate(_vm.user.certificate.file_url)}}},[_c('i',{staticClass:"fad fa-file-certificate"})]):_vm._e(),(_vm.hasUserStatus)?[_c('span',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_missing_verifications"))+" ")]),(
            !_vm.user.missing_verifications ||
            _vm.user.missing_verifications.length == 0
          )?_c('span',{staticClass:"listed-item"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_missing_verifications_none"))+" ")]):_vm._e()]:_vm._e(),_c('oneline-verifications',{attrs:{"verifications":_vm.user.missing_verifications}}),(_vm.user.aml_info || _vm.user.pep_info)?_c('div',{staticClass:"aml-wrapper"},[_c('PADetailAmlPepSumup',{attrs:{"title":"AML","AmlInfo":_vm.user.aml_info,"AmlInfoUpdatedAt":_vm.user.aml_info_updated_at}}),_c('PADetailAmlPepSumup',{attrs:{"title":"PEP","AmlInfo":_vm.user.pep_info,"AmlInfoUpdatedAt":_vm.user.pep_info_updated_at}})],1):_vm._e()],2),_c('div',[(
          _vm.user.certificate &&
          _vm.user.certificate.file_url &&
          _vm.$store.getters.isDesktop
        )?_c('v-button',{staticClass:"small v-icon",attrs:{"flat":"","icon":""},nativeOn:{"click":function($event){return _vm.downloadCertificate(_vm.user.certificate.file_url)}}},[_c('i',{staticClass:"fad fa-file-certificate"})]):_vm._e(),(_vm.hasUserStatus && !_vm.$store.getters.isReadOnlyReviewer)?_c('v-button',{staticClass:"small appended-icon transparent",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.onOpenMisconductModal()}}},[_c('i',{staticClass:"fad fa-badge-sheriff"}),_c('span',[_vm._v(_vm._s(_vm.$t("users_detail_add_to_misconduct_list_button")))])]):_vm._e()],1)]),(_vm.user && (_vm.user.customer_user_id || _vm.user.company_app_meta))?_c('div',{staticClass:"user-info context",class:{ hidden: _vm.loadingProp }},[_c('div',{staticClass:"fields-group"},[(_vm.user && _vm.user.customer_user_id)?_c('div',[_c('div',{staticClass:"fields-group__header"},[_vm._v(" "+_vm._s(_vm.$t("processing_attempts_detail_customer_user_id"))+" ")]),_vm._v(" "+_vm._s(_vm.user.customer_user_id)+" ")]):_vm._e(),(_vm.user && _vm.user.company_app_meta)?_vm._l((Object.keys(_vm.user.company_app_meta)),function(key){return _c('div',{key:key},[_c('div',{staticClass:"fields-group__header"},[_vm._v("Meta "+_vm._s(key))]),_vm._v(" "+_vm._s(_vm.user.company_app_meta[key])+" ")])}):_vm._e()],2)]):_vm._e(),_c('MisconductModalAdd',{attrs:{"misconductOptions":_vm.misconductOptions,"showMisconductDetail":_vm.showMisconductDetail,"user":_vm.user,"resourceDetail":_vm.resourceDetail},on:{"showMisconductDetail":function($event){_vm.showMisconductDetail = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }