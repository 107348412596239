


















































































import KYBService from "@/api/kyb/kybService";
import Vue from "vue";

export default Vue.extend({
  props: {
    KYBCompanyDetail: { required: true, type: Object },
  },
  name: "KYBIndividualEmailReminderButton",
  data() {
    return {
      showIndividualOptions: false,
      loading: false,
      kybIndividualId: "",
    };
  },
  computed: {
    reminderUBOsDisabled(): boolean {
      return (
        (this.KYBCompanyDetail.ubo_list?.length > 0 &&
          this.KYBCompanyDetail.ubo_list.every(
            (ubo: { status: string }) => ubo.status === "SUCCESS"
          )) ||
        !this.KYBCompanyDetail.legal_acceptance_date
      );
    },
    reminderAllIndividualsDisabled(): boolean {
      return (
        this.reminderUBOsDisabled &&
        this.KYBCompanyDetail.admin?.status === "SUCCESS" &&
        !!this.KYBCompanyDetail.admin_email
      );
    },
  },
  methods: {
    async sendAllIndividualsAnEmailReminder() {
      this.kybIndividualId = await this.processAdminOrContact(
        this.KYBCompanyDetail
      );

      await this.sendUBOsEmails(this.kybIndividualId);
    },
    async sendUBOsEmails(kybIndividualId: string) {
      const uboList = this.KYBCompanyDetail.ubo_list;

      if (
        uboList.length &&
        this.$store.state.verificationFlow?.config?.ubo_verification_enabled
      ) {
        const pendingUBOList = uboList.filter(
          ({ status, id }: { status: string; id: string }) =>
            status !== "SUCCESS" && id !== kybIndividualId
        );

        for (const ubo of pendingUBOList) {
          if (ubo.contact?.id) await this.processAdminOrContact(ubo);
          else if (ubo.user?.email)
            await this.sendKYBIndividualEmailReminderButton(ubo.id);
        }
      }
    },
    async processAdminOrContact(kybCompany: any) {
      const { contact, admin, admin_email } = kybCompany;

      const kybIndividualId = admin_email ? admin?.id : contact?.id;
      if (kybIndividualId && admin?.status !== "SUCCESS") {
        await this.sendKYBIndividualEmailReminderButton(kybIndividualId);
      }
      return kybIndividualId;
    },
    async sendKYBIndividualEmailReminderButton(kybIndividualId: string) {
      try {
        this.loading = true;
        await KYBService.sendKYBIndividualEmailReminderButton(kybIndividualId);
      } finally {
        this.loading = false;
      }
    },
  },
});
