




















































import Vue from "vue";

interface TabsInfo {
  [key: string]: {
    percentage: number;
    status: string;
    steps: { label: string; status: string; showStatusPill?: boolean }[];
  };
}

export default Vue.extend({
  components: {},
  name: "KYBProgressTabs",
  props: {
    activeTabName: { required: true, type: String },
    KYBCompanyDetail: { required: true, type: Object },
    adminInformation: { required: true, type: Object },
    KYBCompanyDetailSanctions: { required: true, type: Object },
    KYBCompanyDetailAdverseMedia: { required: true, type: Object },
  },
  watch: {
    "KYBCompanyDetail.admin.id"() {
      if (this.KYBCompanyDetail.admin?.id) this.$emit("showAndGetUBODetail");
    },
  },
  computed: {
    representativeCmesInformation(): any {
      if (!this.adminInformation) return null;

      const getStatus = (key: string | string[]): string =>
        ["SUCCESS", "MANUAL_SUCCESS"].includes(this.adminInformation.status)
          ? "SUCCESS"
          : this.processRepresentativeCmes(
              this.adminInformation?.processing_attempts,
              key
            ) || "BLOCKED";

      return {
        kycPA: getStatus(["PASSPORT", "DRIVING_LICENSE", "NATIONAL_ID"]),
        representativePA: getStatus("REPRESENTATIVE"),
      };
    },
    progressTabsInformation() {
      const tabsInfo = {
        companyInfo: {
          percentage: 0,
          status: "in-progress",
          steps: [
            { label: "progress_tab_company_details", status: "IN_PROGRESS" },
          ],
        },
        extraDocuments: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_document_uploaded", status: "BLOCKED" },
            { label: "progress_tab_legal_acceptance", status: "BLOCKED" },
          ],
        },
        UBODiagram: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_ubo_list", status: "BLOCKED" },
            { label: "progress_tab_legal_acceptance", status: "BLOCKED" },
            {
              label: "progress_tab_ubo_statuses",
              status: "BLOCKED",
              showStatusPill: true,
            },
          ],
        },
        representative: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_representative_mail", status: "BLOCKED" },
            {
              label: "progress_tab_representative_kyc",
              status: "BLOCKED",
              showStatusPill: true,
            },
            {
              label: "progress_tab_representative_match",
              status: "BLOCKED",
              showStatusPill: true,
            },
            {
              label: "progress_tab_legal_acceptance",
              status: "BLOCKED",
            },
          ],
        },
        sanctionsChecks: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_sanctions_checked", status: "BLOCKED" },
            {
              label: "progress_tab_sanctions_analysis",
              status: "BLOCKED",
              showStatusPill: true,
            },
          ],
        },
        adverseMediaChecks: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_adverse_media_checked", status: "BLOCKED" },
            {
              label: "progress_tab_adverse_media_analysis",
              status: "BLOCKED",
              showStatusPill: true,
            },
          ],
        },
        proofOfAddress: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_document_uploaded", status: "BLOCKED" },
            {
              label: "progress_tab_poa_match",
              status: "BLOCKED",
              showStatusPill: true,
            },
          ],
        },
      };
      const flowStats = this.KYBCompanyDetail?.flow_stats;
      const sanctions = this.KYBCompanyDetailSanctions ?? null;
      const adverseMedia = this.KYBCompanyDetailAdverseMedia ?? null;

      const CPOAProcessingAttempts =
        this.KYBCompanyDetail?.processing_attempts?.filter(
          (processingAttempt: any) =>
            processingAttempt.permission_type === "CPOA"
        );

      if (!flowStats) {
        return tabsInfo;
      }

      if (this.KYBCompanyDetail.status === "SCREENING") {
        this.blockAllTabs(tabsInfo);
      }

      this.updateCompanyInfoTab(flowStats, tabsInfo);
      this.updateExtraDocumentsAndCPOATab(
        flowStats,
        tabsInfo,
        CPOAProcessingAttempts
      );
      this.updateUBODiagramTab(flowStats, tabsInfo);
      this.updateRepresentativeTab(tabsInfo);
      this.updateSanctionsAndAdverseMediaChecksTab(
        "sanctionsChecks",
        sanctions,
        tabsInfo
      );
      this.updateSanctionsAndAdverseMediaChecksTab(
        "adverseMediaChecks",
        adverseMedia,
        tabsInfo
      );

      if (this.KYBCompanyDetail?.legal_acceptance_date) {
        tabsInfo.extraDocuments.percentage = 100;
        tabsInfo.extraDocuments.status = "completed";
        tabsInfo.extraDocuments.steps[1].status = "SUCCESS";
        tabsInfo.UBODiagram.percentage = 100;
        tabsInfo.UBODiagram.status = "completed";
        tabsInfo.UBODiagram.steps[1].status = "SUCCESS";
      }

      return tabsInfo;
    },
    progressTabs() {
      const tabs: Array<any> = [];

      const uboDiscovery =
        this.$store.state.verificationFlow?.config?.ubo_discovery_enabled;

      const companyProofOfAddress =
        this.$store.state.verificationFlow?.permissions?.some(
          (permissionsGroup: Array<string>) => permissionsGroup.includes("CPOA")
        );

      const sanctionsChecks =
        this.$store.state.verificationFlow?.config?.sanctions_screening_enabled;

      const adverseMediaChecks =
        this.$store.state.verificationFlow?.config
          ?.adverse_media_screening_enabled;

      tabs.push({
        activeTabName: "companyInfo",
        status: this.progressTabsInformation.companyInfo.status,
        percentage: this.progressTabsInformation.companyInfo.percentage,
        label: "companies_detail_company_info",
        steps: this.progressTabsInformation.companyInfo.steps,
      });

      if (
        (this.$store.state.verificationFlow &&
          this.$store.state.verificationFlow.extra_documents &&
          this.$store.state.verificationFlow.extra_documents.length) ||
        (this.KYBCompanyDetail &&
          this.KYBCompanyDetail.files &&
          this.KYBCompanyDetail.files.length)
      )
        tabs.push({
          activeTabName: "companyExtraDocuments",
          status: this.progressTabsInformation.extraDocuments
            ? this.progressTabsInformation.extraDocuments.status
            : "manual-review",
          percentage: this.progressTabsInformation.extraDocuments
            ? this.progressTabsInformation.extraDocuments.percentage
            : 0,
          label: "companies_detail_files",
          steps: this.progressTabsInformation.extraDocuments.steps,
        });

      tabs.push({
        activeTabName: "representativeDetail",
        status: this.progressTabsInformation.representative
          ? this.progressTabsInformation.representative.status
          : "manual-review",
        percentage: this.progressTabsInformation.representative
          ? this.progressTabsInformation.representative.percentage
          : 0,
        label: "companies_detail_representative",
        steps: this.progressTabsInformation.representative.steps,
      });
      if (uboDiscovery)
        tabs.push({
          activeTabName: "UBODiagram",
          status: this.progressTabsInformation.UBODiagram
            ? this.progressTabsInformation.UBODiagram.status
            : "manual-review",
          percentage: this.progressTabsInformation.UBODiagram
            ? this.progressTabsInformation.UBODiagram.percentage
            : 0,
          label: "companies_detail_ubo_diagram",
          steps: this.progressTabsInformation.UBODiagram.steps,
        });

      if (sanctionsChecks)
        tabs.push({
          activeTabName: "sanctionsChecks",
          status: this.progressTabsInformation.sanctionsChecks
            ? this.progressTabsInformation.sanctionsChecks.status
            : "manual-review",
          percentage: this.progressTabsInformation.sanctionsChecks
            ? this.progressTabsInformation.sanctionsChecks.percentage
            : 0,
          label:
            "verification_flow_settings_verification_requirements_kyb_checks_switch",
          steps: this.progressTabsInformation.sanctionsChecks.steps,
        });

      if (adverseMediaChecks)
        tabs.push({
          activeTabName: "adverseMediaChecks",
          status: this.progressTabsInformation.adverseMediaChecks
            ? this.progressTabsInformation.adverseMediaChecks.status
            : "manual-review",
          percentage: this.progressTabsInformation.adverseMediaChecks
            ? this.progressTabsInformation.adverseMediaChecks.percentage
            : 0,
          label: "progress_tab_adverse_media",
          steps: this.progressTabsInformation.adverseMediaChecks.steps,
        });

      if (companyProofOfAddress)
        tabs.push({
          activeTabName: "proofOfAddress",
          status: this.progressTabsInformation.proofOfAddress
            ? this.progressTabsInformation.proofOfAddress.status
            : "manual-review",
          percentage: this.progressTabsInformation.proofOfAddress
            ? this.progressTabsInformation.proofOfAddress.percentage
            : 0,
          label: "proof_of_address",
          steps: this.progressTabsInformation.proofOfAddress.steps,
        });

      return tabs;
    },
  },
  methods: {
    showAndGetUBODetail(event: string) {
      this.$emit("activeTabName", event);
      this.$emit("showAndGetUBODetail");
    },
    processRepresentativeCmes(
      processingAttempts: Array<any>,
      key: Array<string> | string
    ) {
      if (!processingAttempts?.length) return "BLOCKED";
      const keys = Array.isArray(key) ? key : [key];
      for (const processingAttempt of processingAttempts) {
        if (keys.includes(processingAttempt.permission_type))
          return processingAttempt.status;
      }
    },
    getStatusClassIcon(status: string) {
      //TODO: Move function at lib.ts
      return {
        "fa fa-check-circle":
          status === "SUCCESS" || status === "MANUAL_SUCCESS",
        "fa fa-times-circle": status === "MANUAL_ERROR" || status === "ERROR",
        "fa fa-question-circle":
          status === "PENDING" ||
          status === "MANUAL_REVIEW" ||
          status === "IN_PROGRESS" ||
          status === "BLOCKED",
        [status === "IN_PROGRESS" ? "IN_PROGRESS" : `status_${status}`]: true,
      };
    },
    blockAllTabs(tabsInfo: TabsInfo) {
      for (const tabName in tabsInfo) {
        tabsInfo[tabName].status = "blocked";
      }
    },
    updateCompanyInfoTab(flowStats: any, tabsInfo: TabsInfo) {
      if (flowStats.is_company_set) {
        tabsInfo.companyInfo.percentage = 100;
        tabsInfo.companyInfo.status = "completed";
        tabsInfo.companyInfo.steps[0].status = "SUCCESS";
      }
    },
    updateExtraDocumentsAndCPOATab(
      flowStats: any,
      tabsInfo: TabsInfo,
      CPOAProcessingAttempts: Array<any>
    ) {
      if (flowStats.meets_document_requirements) {
        tabsInfo.extraDocuments.percentage = 33;
      }
      if (
        this.KYBCompanyDetail.status !== "SCREENING" &&
        this.KYBCompanyDetail.files &&
        this.KYBCompanyDetail.files.length
      ) {
        tabsInfo.extraDocuments.percentage = 50;
        tabsInfo.extraDocuments.status = "in-progress";
        tabsInfo.extraDocuments.steps[0].status = "SUCCESS";
      }

      if (CPOAProcessingAttempts && CPOAProcessingAttempts.length) {
        const priorities = [
          "SUCCESS",
          "MANUAL_REVIEW",
          "VERIFICATION_ERROR",
          "ERROR",
        ];
        tabsInfo.proofOfAddress.steps[0].status = "SUCCESS";
        tabsInfo.proofOfAddress.percentage = 33;
        tabsInfo.proofOfAddress.status = "in-progress";

        let finalStatus = CPOAProcessingAttempts[0].status;

        for (const attempt of CPOAProcessingAttempts) {
          if (
            priorities.indexOf(attempt.status) !== -1 &&
            priorities.indexOf(attempt.status) < priorities.indexOf(finalStatus)
          ) {
            finalStatus = attempt.status;
          }
        }

        switch (finalStatus) {
          case "SUCCESS":
            tabsInfo.proofOfAddress.percentage = 100;
            tabsInfo.proofOfAddress.status = "completed";
            tabsInfo.proofOfAddress.steps[1].status = "SUCCESS";
            break;
          case "MANUAL_REVIEW":
            tabsInfo.proofOfAddress.percentage = 50;
            tabsInfo.proofOfAddress.status = "manual-review";
            tabsInfo.proofOfAddress.steps[1].status = "MANUAL_REVIEW";
            break;
          case "VERIFICATION_ERROR":
          case "ERROR":
            tabsInfo.proofOfAddress.percentage = 50;
            tabsInfo.proofOfAddress.status = "error";
            tabsInfo.proofOfAddress.steps[1].status = "VERIFICATION_ERROR";
            break;
        }
      }
    },
    updateUBODiagramTab(flowStats: any, tabsInfo: TabsInfo) {
      if (flowStats.is_ubo_list_set) {
        tabsInfo.UBODiagram.percentage = 33;
        tabsInfo.UBODiagram.status = "in-progress";
        tabsInfo.UBODiagram.steps[0].status = "SUCCESS";
        if (
          this.KYBCompanyDetail.ubo_list &&
          this.KYBCompanyDetail.ubo_list.length
        ) {
          for (const ubo of this.KYBCompanyDetail.ubo_list) {
            if (ubo.ignored) {
              tabsInfo.UBODiagram.percentage = 66;
              if (this.KYBCompanyDetail?.legal_acceptance_date)
                tabsInfo.UBODiagram.status = "completed";
            }
            if (ubo.status && !ubo.ignored) {
              switch (ubo.status) {
                case "SUCCESS":
                case "MANUAL_SUCCESS":
                  tabsInfo.UBODiagram.percentage = 66;
                  tabsInfo.UBODiagram.status = "in-progress";
                  tabsInfo.UBODiagram.steps[2].status = "SUCCESS";
                  if (
                    (ubo.legal_acceptance_date &&
                      this.KYBCompanyDetail?.legal_acceptance_date) ||
                    (!ubo.ubo_list &&
                      this.KYBCompanyDetail?.legal_acceptance_date)
                  ) {
                    tabsInfo.UBODiagram.percentage = 100;
                  }
                  break;
                case "MANUAL_REVIEW":
                  tabsInfo.UBODiagram.percentage = 66;
                  tabsInfo.UBODiagram.status = "manual-review";
                  tabsInfo.UBODiagram.steps[2].status = "MANUAL_REVIEW";
                  break;
                case "ERROR":
                case "MANUAL_ERROR":
                  tabsInfo.UBODiagram.percentage = 66;
                  tabsInfo.UBODiagram.status = "error";
                  tabsInfo.UBODiagram.steps[2].status = "ERROR";
                  break;
                case "PENDING":
                  tabsInfo.UBODiagram.percentage = 66;
                  tabsInfo.UBODiagram.steps[2].status = "IN_PROGRESS";
                  break;
              }
              if (ubo.status !== "SUCCESS") break;
            }
          }
        }
      }

      if (this.KYBCompanyDetail?.legal_acceptance_date) {
        tabsInfo.UBODiagram.percentage = 100;
        tabsInfo.UBODiagram.status = "completed";
        tabsInfo.UBODiagram.steps[1].status = "SUCCESS";
      }
    },
    updateRepresentativeTab(tabsInfo: TabsInfo) {
      if (this.KYBCompanyDetail.admin_email) {
        tabsInfo.representative.percentage = 25;
        tabsInfo.representative.status = "in-progress";
        tabsInfo.representative.steps[0].status = "SUCCESS";
        if (this.KYBCompanyDetail.admin?.user?.first_name) {
          tabsInfo.representative.percentage = 25;
          tabsInfo.representative.steps[0].status = "SUCCESS";
        }
        if (this.representativeCmesInformation) {
          const adminStatus = this.KYBCompanyDetail.admin?.status || "";
          tabsInfo.representative.steps[1].status =
            this.representativeCmesInformation.kycPA;
          tabsInfo.representative.steps[2].status =
            this.representativeCmesInformation.representativePA;
          this.setRepresentativeTabInfo(adminStatus, tabsInfo);
        }
        if (this.KYBCompanyDetail?.legal_acceptance_date) {
          tabsInfo.representative.steps[3].status = "SUCCESS";
        }
      }
    },
    setRepresentativeTabInfo(adminStatus: string, tabsInfo: TabsInfo) {
      tabsInfo.representative.percentage = 50;
      switch (adminStatus) {
        case "SUCCESS":
        case "MANUAL_SUCCESS":
          tabsInfo.representative.percentage = 100;
          tabsInfo.representative.status = "completed";
          break;
        case "PENDING":
          tabsInfo.representative.status = "in-progress";
          break;
        case "MANUAL_REVIEW":
          tabsInfo.representative.status = "manual-review";
          break;
        case "ERROR":
        case "MANUAL_ERROR":
          tabsInfo.representative.status = "error";
          break;
      }
    },
    updateSanctionsAndAdverseMediaChecksTab(
      checksKey: "sanctionsChecks" | "adverseMediaChecks",
      checksObj: any,
      tabsInfo: TabsInfo
    ) {
      if (Object.keys(checksObj).length) {
        tabsInfo[checksKey].percentage = 33;
        tabsInfo[checksKey].status = "in-progress";
        tabsInfo[checksKey].steps[0].status = "IN_PROGRESS";

        if (checksObj.has_hits) {
          tabsInfo[checksKey].percentage = 50;
          tabsInfo[checksKey].status = "manual-review";
          tabsInfo[checksKey].steps[1].status = "MANUAL_REVIEW";
        }

        if (["IGNORED", "CONFIRMED"].includes(checksObj.status)) {
          tabsInfo[checksKey].percentage = 100;
          tabsInfo[checksKey].status =
            checksObj.status === "IGNORED" ? "completed" : "error";
          tabsInfo[checksKey].steps[0].status = "SUCCESS";
          tabsInfo[checksKey].steps[1].status =
            checksObj.status === "IGNORED" ? "SUCCESS" : "ERROR";
        }

        if (checksObj.status === "CONFIRMED" && !checksObj.has_hits) {
          tabsInfo[checksKey].status = "completed";
          tabsInfo[checksKey].steps[1].status = "SUCCESS";
        }
      }
    },
  },
});
