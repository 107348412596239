

































import Vue from "vue";
import CompaniesService from "@/api/companies/companiesService";
import { mutationsT } from "@/state/mutations";
import eventBus from "@/lib/utils";

export default Vue.extend({
  name: "DuplicateVerificationFlowModal",
  props: {
    verificationFlowProps: {
      required: true,
      default: {} as any,
    },
  },
  data() {
    return {
      checkIfCanDelete: "",
      responseDeleteError: "",
      loading: false,
      duplicatedName: "",
      duplicatedAlias: "",
    };
  },
  methods: {
    removeUnnecessaryProperties(verificationFlow: any) {
      const propertiesToRemove = [
        "app_id",
        "created_at",
        "updated_at",
        "webhook_url",
        "admins",
      ];
      propertiesToRemove.forEach((property) => {
        if (verificationFlow.hasOwnProperty(property)) {
          delete verificationFlow[property];
        }
      });
    },
    async createNewVerificationFlow() {
      this.loading = true;
      try {
        let verificationFlow = this.verificationFlowProps;
        verificationFlow.name = this.duplicatedName
          ? this.duplicatedName
          : `${this.verificationFlowProps.name} (copy)`;
        verificationFlow.alias = this.duplicatedAlias
          ? this.duplicatedAlias
          : `${this.verificationFlowProps.alias} (copy)`;

        this.removeUnnecessaryProperties(verificationFlow);

        const newVerificationFlow = await CompaniesService.postCompanyApp(
          verificationFlow
        );
        this.$root.$emit(
          "analytics",
          "AddANewVerificationFlow - CreateFlow Clicked"
        );
        eventBus.$emit("v-toast", {
          text: this.$t("successful_create_verification_flow_feedback", {
            verificationFlowName: newVerificationFlow.data.name,
          }),
          type: "success-toast",
        });
        const companies = await CompaniesService.getCompanies();
        this.$store.commit(mutationsT.UPDATE_COMPANIES, companies.data.results);
        this.$emit("closeDuplicateDetail", false);
      } finally {
        const company = await CompaniesService.getCompany(
          this.$store.state.company.id
        );
        this.$store.commit(mutationsT.UPDATE_COMPANY, company.data);
        const companies = await CompaniesService.getCompanies();
        this.$store.commit(mutationsT.UPDATE_COMPANIES, companies.data.results);
        this.loading = false;
        this.duplicatedName = "";
        this.duplicatedAlias = "";
      }
    },
  },
});
