







































































































































































































































































































































































import Vue, { PropType } from "vue";
import PADetailCDPSectionDropDown from "./components/PADetailCDPSectionDropDown.vue";
import PADetailVerificationInfo from "./components/PADetailVerificationInfo.vue";
import VToast from "@/components/vuemarc-ui-kit/toast/VToast.vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import VExpandInput from "@/components/vuemarc-ui-kit/inputs/VExpandInput.vue";
import {
  CDP_CHANGELOG_STATES,
  CDP_DEFAULT_SKELETON,
  CdpDefaultSkeleton,
  TaxChangesDifference,
  LineItemChangesDifference,
  DiscountAndFeeChangesDifference,
  PERCENTAGE_INPUTS,
  LockedBy,
} from "@/models/cdp";
import VMultiselectInput from "@/components/vuemarc-ui-kit/inputs/VMultiselectInput.vue";
import { TranslateResult } from "vue-i18n";
import {
  KeyToDataMap,
  sections,
  sectionValue,
  tableValues,
} from "./models/cdp/cdpModels";
import { v4 as uuidv4 } from "uuid";
import Decimal from "decimal.js";
import ResourcesService from "@/api/resources/resourcesService";

interface ComponentData {
  showHomography: boolean;
  statusOptions: { value: string }[];
  errorOptions: { text: TranslateResult; value: string }[];
  editCDPComment: boolean;
  documentData: any;
  showDifferencesInput: boolean;
  focusSectionName: string;
  localInvoiceDocumentVersion: CdpDefaultSkeleton | any;
  activeInput: string;
  showChangeLogDifferences: boolean;
  showDiscardReasonNeeded: boolean;
  generalValues: sectionValue[];
  paymentValues: sectionValue[];
  supplierValues: sectionValue[];
  totalsValues: sectionValue[];
  discountsAndFeesValues: tableValues;
  taxValues: tableValues;
  lineItemsValues: tableValues;
  resourceFile: any;
  loadingFiles: boolean;
}

export default Vue.extend({
  name: "PADetailCDP",
  components: {
    PADetailVerificationInfo,
    PADetailCDPSectionDropDown,
    VToast,
    VTab,
    VExpandInput,
    VMultiselectInput,
  },
  props: {
    resourceDetail: {
      required: true,
      type: Object as () => CdpDefaultSkeleton,
    },
    lastInvoiceDocumentFromDB: {
      required: true,
      type: Object as () => CdpDefaultSkeleton,
    },
    lastVersionDocumentProcessProps: { required: false, type: Object },
    processingAttempt: { required: true, type: Object },
    showDifferences: { required: true, type: Boolean },
    isLockedBy: {
      required: true,
      type: null as unknown as PropType<LockedBy>,
    },
    isLastVersion: { required: true, type: Boolean },
    activeCDP: { required: true, type: String },
    newVersionAvailable: { required: false, type: Boolean },
    loadingProp: { required: true, type: Boolean },
    changelogProp: { required: true, type: Array<any> },
    loadingChangelog: { required: false, type: Boolean },
  },

  data(): ComponentData {
    return {
      resourceFile: null,
      loadingFiles: false,
      showHomography: false,
      statusOptions: [
        { value: "APPROVED" },
        { value: "DISCARDED" },
        { value: "MANUAL_REVIEW" },
      ],
      errorOptions: [
        {
          text: this.$t("multiple_documents_per_page"),
          value: "MULTIPLE_DOCUMENTS_PER_PAGE",
        },
        {
          text: this.$t("handwritten_document"),
          value: "HANDWRITTEN_DOCUMENT",
        },
        {
          text: this.$t("invalid_document_type"),
          value: "INVALID_DOCUMENT_TYPE",
        },
        {
          text: this.$t("missing_document_pages"),
          value: "MISSING_DOCUMENT_PAGES",
        },
        { text: this.$t("low_picture_quality"), value: "LOW_PICTURE_QUALITY" },
        { text: this.$t("duplicate_document"), value: "DUPLICATE_DOCUMENT" },
      ],
      editCDPComment: false,
      documentData: {} as any,
      showDifferencesInput: false,
      focusSectionName: "",
      localInvoiceDocumentVersion: JSON.parse(
        JSON.stringify(this.lastInvoiceDocumentFromDB)
      ),
      activeInput: "",
      showChangeLogDifferences: false,
      showDiscardReasonNeeded: false,
      generalValues: [],
      paymentValues: [],
      supplierValues: [],
      totalsValues: [],
      discountsAndFeesValues: {
        headers: [
          "documents_details_discounts_and_fees_base_amount",
          "documents_details_discounts_and_fees_discount_percentage",
          "documents_details_discounts_and_fees_discount_amount",
          "documents_details_discounts_and_fees_fees_percentage",
          "documents_details_discounts_and_fees_fees_amount",
          "documents_details_discounts_and_fees_total",
        ],
        rows: [],
      },
      taxValues: {
        headers: [
          "documents_details_tax_base_amount",
          "documents_details_tax_iva_percentage",
          "documents_details_tax_iva_amount",
          "documents_details_tax_re_percentage",
          "documents_details_tax_re_amount",
          "documents_details_tax_total",
        ],
        rows: [],
      },
      lineItemsValues: {
        headers: {
          documents_details_line_items_product_details: [
            "documents_details_line_items_product_details_delivery_note_reference",
            "documents_details_line_items_product_details_identifier",
            "documents_details_line_items_product_details_UOM_description",
          ],
          documents_details_line_items_quantity: [
            "documents_details_line_items_quantity_units",
            "documents_details_line_items_quantity_package",
            "documents_details_tax_total",
          ],
          documents_details_line_items_unitary_price: [
            "documents_details_line_items_unitary_price_gross_amount",
            "documents_details_line_items_unitary_price_discount_amount",
            "documents_details_line_items_unitary_price_green_dot",
            "documents_details_line_items_unitary_price_net_amount",
            "documents_details_line_items_unitary_price_total_price",
          ],
          documents_details_line_items_discounts_and_fees: [
            "documents_details_line_items_discounts_and_fees_gross_amount",
            "documents_details_discounts_and_fees_discount_percentage",
            "documents_details_discounts_and_fees_discount_amount",
            "documents_details_totals_green_dot",
            "documents_details_line_items_unitary_price_ibee",
            "documents_details_discounts_and_fees_fees_percentage",
            "documents_details_discounts_and_fees_fees_amount",
          ],
          documents_details_line_items_tax: [
            "documents_details_line_items_tax_base_amount",
            "documents_details_line_items_tax_percentage",
            "documents_details_totals_tax_amount",
          ],
          documents_details_line_items_total_amount: [
            "documents_details_line_items_total_amount",
          ],
        },
        rows: [],
      },
    };
  },
  computed: {
    isFirstVersionSelected(): boolean {
      return (
        this.resourceDetail.id ===
        this.changelogProp[this.changelogProp.length - 1]?.id
      );
    },
    mandatoryFieldsMissing(): boolean {
      const commonFieldsFilled =
        !!this.localInvoiceDocumentVersion.data?.document_type?.value &&
        !!this.localInvoiceDocumentVersion.data?.document_number?.value;

      if (this.localInvoiceDocumentVersion.status === "DISCARDED") {
        this.showDiscardReasonNeeded = this.localInvoiceDocumentVersion.error
          ? false
          : true;
      }

      return (
        !commonFieldsFilled &&
        this.localInvoiceDocumentVersion.status !== "DISCARDED"
      );
    },
    discardReasonMissing(): boolean {
      return (
        this.localInvoiceDocumentVersion.status === "DISCARDED" &&
        !this.localInvoiceDocumentVersion.error
      );
    },
    reviewerEmail(): string {
      if (this.resourceDetail?.reviewer_user?.email?.includes("siltapp"))
        return "Anonymous";
      return this.resourceDetail?.reviewer_user?.email?.toLowerCase() || "";
    },
    commentKeys(): string[] {
      let hasRequiredChars = 0;
      let requiredChars = ["[", "]", "{", "}"];
      if (!this.localInvoiceDocumentVersion.comment) return [];

      requiredChars.forEach((char) => {
        if (this.localInvoiceDocumentVersion.comment.includes(char))
          hasRequiredChars++;
      });
      if (hasRequiredChars !== requiredChars.length) return [];

      let keysString = "";
      const startIndex = this.localInvoiceDocumentVersion.comment.indexOf("[");
      const endIndex =
        this.localInvoiceDocumentVersion.comment.lastIndexOf("]");

      if (startIndex !== -1 && endIndex !== -1) {
        keysString = this.localInvoiceDocumentVersion.comment.substring(
          startIndex + 1,
          endIndex
        );
      }
      return keysString
        .split(",")
        .map((key: string) => key.trim().replace(/['‘’´`"]/g, ""))
        .filter((key) => key.length > 0);
    },
    commentParsed(): string {
      if (!this.localInvoiceDocumentVersion.comment) return "-";

      return this.removeContentBetweenBraces(
        this.localInvoiceDocumentVersion.comment
      );
    },
    sections(): sections[] {
      return [
        {
          sectionName: "documents_details_general_title",
          key: "generalValues",
          values: this.generalValues,
        },
        {
          sectionName: "documents_details_payment_title",
          key: "paymentValues",
          values: this.paymentValues,
        },
        {
          sectionName: "documents_details_supplier_title",
          key: "supplierValues",
          values: this.supplierValues,
        },
        {
          sectionName: "documents_details_totals_title",
          key: "totalsValues",
          values: this.totalsValues,
        },
        {
          sectionName: "documents_details_discounts_and_fees_title",
          key: "discountsAndFeesValues",
          dataKey: "discounts_and_fees",
          tableValues: this.discountsAndFeesValues,
        },
        {
          sectionName: "documents_details_tax_title",
          key: "taxValues",
          dataKey: "tax",
          tableValues: this.taxValues,
        },
        {
          sectionName: "documents_details_line_items_title",
          key: "lineItemsValues",
          dataKey: "line_items",
          tableValues: this.lineItemsValues,
        },
      ];
    },
    cmePVErrors(): any {
      return (
        this.processingAttempt.core_module_executions?.length &&
        this.processingAttempt.core_module_executions.find(
          (cme: any) => cme.type === "PICTURE_VALIDATION"
        )
      );
    },
    qualityChecks(): any {
      const cmePVErrors = this.cmePVErrors;
      const qualityChecks = {
        blurry: this.getErrorStatus(
          cmePVErrors?.errors?.includes("E_IMAGE_BLURRY"),
          "E_IMAGE_BLURRY",
          "not_blurry"
        ),
        expected_document: this.getErrorStatus(
          cmePVErrors?.errors?.includes("E_UNEXPECTED_DOCUMENT_TYPE"),
          "E_UNEXPECTED_DOCUMENT_TYPE",
          "cdp_detail_expected_document_type_invoice"
        ),
        validation_rules: {
          status: this.localInvoiceDocumentVersion.comment
            ? "ERROR"
            : "BLOCKED",
          copy: this.parseCommentToError(
            this.localInvoiceDocumentVersion.comment
          ),
        },
      };

      return qualityChecks;
    },
    isLastVersionAndNotLockedByCurrentUser(): boolean {
      const isLockedBy =
        !!this.isLockedBy &&
        this.isLockedBy?.id !== this.$store.state?.user?.id;
      return this.isLastVersion && !isLockedBy;
    },
    activeUpdatedDataFileds(): Array<string> {
      return this.getChangeLogChanges(this.resourceDetail?.data);
    },
  },
  watch: {
    processingAttempt: {
      handler(oldValue, newValue) {
        if (oldValue.id !== newValue.id) {
          this.resetData();
        }
        this.fetchFiles();
      },
    },
    resourceFile: {
      deep: true,
      immediate: true,
      handler() {
        if (this.resourceFile && this.resourceFile.file_homography_url) {
          this.showHomography = true;
        } else {
          this.showHomography = false;
        }
      },
    },
    localInvoiceDocumentVersion: {
      deep: true,
      handler(oldValue, newValue) {
        this.generalValues = [
          {
            key: "data.document_type.value",
            label: "documents_details_general_document_type",
            value: this.localInvoiceDocumentVersion.data?.document_type?.value,
            oldValue: this.lastInvoiceDocumentFromDB.data?.document_type?.value,
            confidence: this.resourceDetail.data?.document_type?.confidence,
            mandatory: true,
            options: [
              {
                text: this.$t("cdp_detail_expected_document_type_invoice"),
                value: "invoice",
              },
              {
                text: this.$t(
                  "cdp_detail_expected_document_type_delivery_note"
                ),
                value: "delivery-note",
              },
              {
                text: this.$t("cdp_detail_expected_document_type_receipt"),
                value: "receipt",
              },
            ],
          },
          {
            key: "data.document_subtype.value",
            label: "documents_details_general_document_subtype",
            value:
              this.localInvoiceDocumentVersion.data?.document_subtype?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.document_subtype?.value,
            confidence: this.resourceDetail.data?.document_subtype?.confidence,
            options: [
              {
                text: this.$t("cdp_detail_expected_document_subtype_regular"),
                value: "REGULAR",
              },
              {
                text: this.$t(
                  "cdp_detail_expected_document_subtype_corrective"
                ),
                value: "CORRECTIVE",
              },
              {
                text: this.$t("cdp_detail_expected_document_subtype_refund"),
                value: "REFUND",
              },
              {
                text: this.$t("cdp_detail_expected_document_subtype_delivery"),
                value: "DELIVERY",
              },
            ],
          },
          {
            key: "data.document_number.value",
            label: "documents_details_general_document_number",
            value:
              this.localInvoiceDocumentVersion.data?.document_number?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.document_number?.value,
            confidence: this.resourceDetail.data?.document_number?.confidence,
            mandatory: true,
          },
          {
            key: "data.document_series_number.value",
            label: "documents_details_general_document_series_number",
            value:
              this.localInvoiceDocumentVersion.data?.document_series_number
                ?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.document_series_number
                ?.value,
            confidence:
              this.resourceDetail.data?.document_series_number?.confidence,
          },
          {
            key: "data.document_issue_date.value",
            label: "documents_details_general_issue_date",
            value:
              this.localInvoiceDocumentVersion.data?.document_issue_date?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.document_issue_date?.value,
            confidence:
              this.resourceDetail.data?.document_issue_date?.confidence,
            isDate: true,
          },
        ];
        this.paymentValues = [
          {
            key: "data.payment_due_date.value",
            label: "documents_details_payment_due_date",
            value:
              this.localInvoiceDocumentVersion.data?.payment_due_date?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.payment_due_date?.value,
            confidence: this.resourceDetail.data?.payment_due_date?.confidence,
            isDate: true,
          },
          {
            key: "data.payment_due_amount.value",
            label: "documents_details_payment_due_amount",
            value:
              this.localInvoiceDocumentVersion.data?.payment_due_amount?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.payment_due_amount?.value,
            confidence:
              this.resourceDetail.data?.payment_due_amount?.confidence,
          },
          {
            key: "data.payment_currency.value",
            label: "documents_details_payment_currency",
            value:
              this.localInvoiceDocumentVersion.data?.payment_currency?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.payment_currency?.value,
            confidence: this.resourceDetail.data?.payment_currency?.confidence,
          },
          {
            key: "data.payment_method.value",
            label: "documents_details_payment_method",
            value: this.localInvoiceDocumentVersion.data?.payment_method?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.payment_method?.value,
            confidence: this.resourceDetail.data?.payment_method?.confidence,
          },
          {
            key: "data.payment_status.value",
            label: "documents_details_payment_status",
            value: this.localInvoiceDocumentVersion.data?.payment_status?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.payment_status?.value,
            confidence: this.resourceDetail.data?.payment_status?.confidence,
          },
        ];
        this.supplierValues = [
          {
            key: "data.supplier_vat_code.value",
            label: "documents_details_supplier_vat_code",
            value:
              this.localInvoiceDocumentVersion.data?.supplier_vat_code?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.supplier_vat_code?.value,
            confidence: this.resourceDetail.data?.supplier_vat_code?.confidence,
          },
          {
            key: "data.supplier_name.value",
            label: "documents_details_supplier_name",
            value: this.localInvoiceDocumentVersion.data?.supplier_name?.value,
            oldValue: this.lastInvoiceDocumentFromDB.data?.supplier_name?.value,
            confidence: this.resourceDetail.data?.supplier_name?.confidence,
          },
        ];
        this.totalsValues = [
          {
            key: "data.total_gross_amount.value",
            label: "documents_details_totals_gross_amount",
            value:
              this.localInvoiceDocumentVersion.data?.total_gross_amount?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.total_gross_amount?.value,
            confidence:
              this.resourceDetail.data?.total_gross_amount?.confidence,
          },
          {
            key: "data.total_discount_amount.value",
            label: "documents_details_totals_discount_amount",
            value:
              this.localInvoiceDocumentVersion.data?.total_discount_amount
                ?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.total_discount_amount?.value,
            confidence:
              this.resourceDetail.data?.total_discount_amount?.confidence,
          },
          {
            key: "data.total_fees_amount.value",
            label: "documents_details_totals_fees_amount",
            value:
              this.localInvoiceDocumentVersion.data?.total_fees_amount?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.total_fees_amount?.value,
            confidence: this.resourceDetail.data?.total_fees_amount?.confidence,
          },
          {
            key: "data.total_ibee.value",
            label: "documents_details_totals_ibee",
            value: this.localInvoiceDocumentVersion.data?.total_ibee?.value,
            oldValue: this.lastInvoiceDocumentFromDB.data?.total_ibee?.value,
            confidence: this.resourceDetail.data?.total_ibee?.confidence,
          },
          {
            key: "data.total_green_dot.value",
            label: "documents_details_totals_green_dot",
            value:
              this.localInvoiceDocumentVersion.data?.total_green_dot?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.total_green_dot?.value,
            confidence: this.resourceDetail.data?.total_green_dot?.confidence,
          },
          {
            key: "data.total_net_amount.value",
            label: "documents_details_totals_net_amount",
            value:
              this.localInvoiceDocumentVersion.data?.total_net_amount?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.total_net_amount?.value,
            confidence: this.resourceDetail.data?.total_net_amount?.confidence,
          },
          {
            key: "data.total_tax_amount.value",
            label: "documents_details_totals_tax_amount",
            value:
              this.localInvoiceDocumentVersion.data?.total_tax_amount?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.total_tax_amount?.value,
            confidence: this.resourceDetail.data?.total_tax_amount?.confidence,
          },
          {
            key: "data.total_irpf.value",
            label: "documents_details_totals_IRPF",
            value: this.localInvoiceDocumentVersion.data?.total_irpf?.value,
            oldValue: this.lastInvoiceDocumentFromDB.data?.total_irpf?.value,
            confidence: this.resourceDetail.data?.total_irpf?.confidence,
          },
          {
            key: "data.total_additional_cost.value",
            label: "documents_details_totals_additional_cost",
            value:
              this.localInvoiceDocumentVersion.data?.total_additional_cost
                ?.value,
            oldValue:
              this.lastInvoiceDocumentFromDB.data?.total_additional_cost?.value,
            confidence:
              this.resourceDetail.data?.total_additional_cost?.confidence,
          },
          {
            key: "data.total_cost.value",
            label: "documents_details_totals_total",
            value: this.localInvoiceDocumentVersion.data?.total_cost?.value,
            oldValue: this.lastInvoiceDocumentFromDB.data?.total_cost?.value,
            confidence: this.resourceDetail.data?.total_cost?.confidence,
          },
        ];
        this.discountsAndFeesValues.rows =
          this.localInvoiceDocumentVersion.data?.discounts_and_fees;
        this.taxValues.rows = this.localInvoiceDocumentVersion.data?.tax;
        this.lineItemsValues.rows =
          this.localInvoiceDocumentVersion.data?.line_items;
        if (this.isLastVersionAndNotLockedByCurrentUser)
          this.$emit(
            "updateLocalInvoiceDocumentVersionAndGetDifferences",
            this.localInvoiceDocumentVersion
          );
      },
    },
    "localInvoiceDocumentVersion.status": {
      handler() {
        if (this.localInvoiceDocumentVersion.status === "APPROVED")
          this.localInvoiceDocumentVersion.error = null;
      },
      immediate: true,
    },
    resourceDetail: {
      deep: true,
      handler(oldValue, newValue) {
        if (!this.resourceDetail.data) return;
        this.showChangeLogDifferences = false;
        this.showDifferencesInput = false;
        this.localInvoiceDocumentVersion = JSON.parse(
          JSON.stringify(this.resourceDetail)
        );
        this.localInvoiceDocumentVersion.data.discounts_and_fees =
          this.parseResourceArrayFieldToChangesDifferenceSchema(
            this.localInvoiceDocumentVersion.data?.discounts_and_fees
          );
        this.localInvoiceDocumentVersion.data.tax =
          this.parseResourceArrayFieldToChangesDifferenceSchema(
            this.localInvoiceDocumentVersion.data?.tax
          );
        this.localInvoiceDocumentVersion.data.line_items =
          this.parseResourceArrayFieldToChangesDifferenceSchema(
            this.localInvoiceDocumentVersion.data?.line_items
          );
      },
    },
    isLastVersionAndNotLockedByCurrentUser() {
      if (!this.isLastVersionAndNotLockedByCurrentUser)
        this.editCDPComment = false;
    },
  },
  methods: {
    resetData() {
      this.$emit("setChangelog", []);
    },
    async fetchFiles() {
      try {
        this.loadingFiles = true;
        if (this.processingAttempt.id) {
          const { data } = await ResourcesService.getResourceFiles(
            this.processingAttempt.id
          );
          this.resourceFile =
            (data && data.files && data.files.length && data.files[0]) || {};
        }
      } finally {
        this.loadingFiles = false;
      }
    },
    parseCommentToError(inputString: string) {
      if (!inputString)
        return this.$t("users_detail_missing_verifications_none");
      const startIndex = inputString.indexOf("'");
      if (startIndex !== -1) {
        const endIndex = inputString.indexOf("'", startIndex + 1);
        if (endIndex !== -1) {
          return this.$options?.filters?.clean_cap_error(
            inputString.substring(startIndex + 1, endIndex)
          );
        }
      }
    },
    removeContentBetweenBraces(comment: string) {
      let insideBraces = 0;
      return comment
        .split("")
        .filter((char) => {
          if (char === "{") insideBraces++;
          if (insideBraces === 0) return true;
          if (char === "}") insideBraces--;
          return false;
        })
        .join("");
    },
    saveChangesAndUpdateCDP(status: string) {
      if (["APPROVED", "DISCARDED"].includes(status))
        this.localInvoiceDocumentVersion.status = status;
      if (status === "APPROVED") this.localInvoiceDocumentVersion.error = null;
      this.$emit("updateInvoiceDocument", this.localInvoiceDocumentVersion);
    },
    updateFocusSectionName(sectionName: string) {
      this.focusSectionName =
        sectionName === this.focusSectionName ? "" : sectionName;
    },
    parseResourceArrayFieldToChangesDifferenceSchema(
      array: (
        | TaxChangesDifference[]
        | LineItemChangesDifference[]
        | DiscountAndFeeChangesDifference[]
      )[]
    ):
      | (
          | TaxChangesDifference[]
          | LineItemChangesDifference[]
          | DiscountAndFeeChangesDifference[]
        )[]
      | [] {
      if (!array) return [];
      return array.map((item: any) => {
        const newItem: any = {};
        const hasBeenParsered = !item.value;
        if (hasBeenParsered) return item;
        if (item?.value)
          Object.keys(item.value).forEach((key) => {
            if (key === "id") newItem[key] = item?.value[key];
            else
              newItem[key] = {
                action: item?.value[key]?.action,
                value: this.formatPercentageIfApplicable(item?.value, key),
                oldValue: this.formatPercentageIfApplicable(item?.value, key),
                confidence: item?.value[key]?.confidence,
                key: key,
              };
          });

        return newItem;
      });
    },
    formatPercentageIfApplicable(value: any, key: string) {
      if (PERCENTAGE_INPUTS.includes(key) && value[key] && value[key].value) {
        const rawValue = value[key].value;

        const cleanedValue = rawValue.toString().replace(/[^0-9.]/g, "");

        const parsedValue = !isNaN(parseFloat(cleanedValue))
          ? new Decimal(parseFloat(cleanedValue))
          : new Decimal(0);

        const isPercentageFormat = rawValue.toString().includes("%");

        return isPercentageFormat ? parsedValue : parsedValue.mul(100);
      }

      return value[key]?.value;
    },
    getChangeLogChanges(activeCDPDocument: any): Array<string> {
      const modifiedKeys = [] as Array<string>;
      for (const property in activeCDPDocument) {
        for (const propertyKey in activeCDPDocument[property]) {
          if (
            propertyKey === "action" &&
            activeCDPDocument[property][propertyKey] !==
              CDP_CHANGELOG_STATES.NONE
          ) {
            modifiedKeys.push(property);
          }
        }
      }

      return modifiedKeys || [""];
    },
    // TODO L1: Undescriptive naming
    updateProperty(key: string, event: any) {
      const { index, value, modifiedKey } = event;
      let modifiedField = (this as any)[key]
        ? JSON.parse(JSON.stringify((this as any)[key]))
        : null;
      if (!modifiedField) {
        const rows = this.localInvoiceDocumentVersion.data[key];
        rows[index][modifiedKey] = value;
        return;
      }
      let updatedModels: sectionValue[] = [...modifiedField];
      updatedModels[index].value = value;
      (this as any)[key] = updatedModels;
      this.updateLocalCDPDocument((this as any)[key]);
    },
    addEmptyRow(key: string) {
      const emptyInput = {} as any;
      let modifiedField = (this as any)[key];
      const keys = Object.keys(
        modifiedField.rows?.length && modifiedField.rows[0]
      );
      if (keys.length)
        keys.forEach((key: string) => {
          emptyInput[key] = { value: "", oldValue: "", key: key };
        });
      if (Object.keys(emptyInput).length) {
        emptyInput.id = uuidv4();
        emptyInput.id_next.value = null;
        if (modifiedField.rows.length) {
          const lastRow = modifiedField.rows[modifiedField.rows.length - 1];
          lastRow.id_next.value = emptyInput.id;
        }
        modifiedField.rows.push(emptyInput);
      } else {
        const keyToDataMap: KeyToDataMap = {
          discountsAndFeesValues: CDP_DEFAULT_SKELETON.data.discounts_and_fees,
          taxValues: CDP_DEFAULT_SKELETON.data.tax,
          lineItemsValues: CDP_DEFAULT_SKELETON.data.line_items,
        };

        if (key in keyToDataMap) {
          modifiedField.rows.push(...keyToDataMap[key]);
        }
      }
    },
    deleteRow(key: string, index: number) {
      let modifiedField = (this as any)[key];
      if (index >= 0 && index < modifiedField.rows.length) {
        modifiedField.rows.splice(index, 1);
        for (let i = 0; i < modifiedField.rows.length; i++) {
          let currentRow = modifiedField.rows[i];
          if (i < modifiedField.rows.length - 1)
            currentRow.id_next.value = modifiedField.rows[i + 1].id;
          else currentRow.id_next.value = null;
        }
      }
    },
    // TODO L1: Undescriptive naming
    updateLocalCDPDocument(newValue: sectionValue[]) {
      newValue.forEach((value: sectionValue) => {
        const keys = value.key.split(".");
        let currentObj = this.localInvoiceDocumentVersion;
        for (let i = 0; i < keys.length - 1; i++) {
          if (currentObj.hasOwnProperty(keys[i]))
            currentObj = currentObj[keys[i]];
        }
        currentObj[keys[keys.length - 1]] = value.value;
      });
    },
    getErrorStatus(
      hasError: boolean,
      errorCode: string,
      successCopy: string
    ): { status: string; copy: string } {
      return {
        status: hasError ? "ERROR" : "SUCCESS",
        copy: hasError ? errorCode.toLowerCase() : successCopy,
      };
    },
  },
});
