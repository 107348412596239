






































































































import KYBService from "@/api/kyb/kybService";
import Vue from "vue";

export default Vue.extend({
  components: {},
  name: "KYBAdverseMediaChecks",
  props: {
    adverseMedia: { required: true, type: Object },
    kybCompanyId: { required: true, type: String },
  },
  data() {
    return {};
  },
  computed: {
    bannerClass() {
      return {
        error: this.adverseMedia.status === "CONFIRMED",
        success: this.adverseMedia.status === "IGNORED",
      };
    },
    hasAdverseMediaRawData(): boolean {
      return (
        this.adverseMedia &&
        this.adverseMedia.raw_data &&
        this.adverseMedia.raw_data.length
      );
    },
    bannerMessage() {
      const manualReviewBannerMessage = "adverse_media_detail_action_subtitle";
      const confirmedBannerMessage =
        "companies_detail_sanctions_check_description_confirmed";
      const ignoredBannerMessage =
        "companies_detail_sanctions_check_description_ignored";
      return (
        (["PENDING_MANUAL_REVIEW"].includes(this.adverseMedia.status)
          ? manualReviewBannerMessage
          : ["CONFIRMED"].includes(this.adverseMedia.status)
          ? confirmedBannerMessage
          : ignoredBannerMessage) || ""
      );
    },
  },
  methods: {
    async ignoreOrConfirmAdverseMedia(action: string) {
      await KYBService.ignoreOrConfirmAdverseMedia(
        this.kybCompanyId,
        this.adverseMedia.id,
        action
      );

      this.$emit("getKYBCompanyDetail");
    },
  },
});
