

















import { Component, Vue, Watch } from "vue-property-decorator";
import MainNav from "@/views/nav/MainNav.vue";
import * as amplitude from "@amplitude/analytics-browser";
import { init } from "@amplitude/analytics-browser";

@Component({
  components: { MainNav },
})
export default class App extends Vue {
  created() {
    this.$root.$on("analytics", (event: string) => {
      if (this.isSiltUser) return;
      amplitude.track(event);
    });
  }
  get isSiltUser() {
    const userEmail = this.$store.state.user?.email;
    return userEmail && userEmail.includes("getsilt.com");
  }
  get hasCompanies() {
    return this.$store.state.companies && this.$store.state.companies.length;
  }
  @Watch("isSiltUser")
  onUserEmailChange() {
    this.initAmplitude();
  }
  initAmplitude() {
    if (this.isSiltUser) return;
    if (this.$store.state.user && this.$store.state.user.email)
      init(
        process.env.VUE_APP_AMPLITUDE_API_KEY as string,
        this.$store.state.user.email
      );
  }

  getStoredLanguage() {
    const cookies = document.cookie.split(";");

    let storedLanguage = null;
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split("=");
      if (name === "localeLanguage") {
        storedLanguage = decodeURIComponent(value);
        break;
      }
    }

    if (storedLanguage) {
      this.$i18n.locale = storedLanguage;
    }
  }

  mounted() {
    this.initAmplitude();
    this.getStoredLanguage();
  }
}
