

















import Vue from "vue";

export default Vue.extend({
  name: "KYBModalDetailHeader",
  props: {
    uboDetail: { required: true, type: Object },
  },
  computed: {
    fullName(): string {
      if (this.uboDetail && this.uboDetail.name) {
        return this.uboDetail.name.toLowerCase();
      } else if (
        this.uboDetail &&
        this.uboDetail.user &&
        this.uboDetail.user.first_name
      ) {
        let fullName = this.uboDetail.user.first_name;
        if (this.uboDetail.user.last_name) {
          fullName += " " + this.uboDetail.user.last_name;
        }
        return fullName.toLowerCase();
      } else {
        return "";
      }
    },
  },
});
