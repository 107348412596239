
























































































import {
  KYC_VERIFICATIONS_TYPES_VIEW_MAP,
  VERIFICATIONS_TYPES_VIEW_MAP,
  VerificationAPIType,
} from "@/models/verificationsTypes";
import Vue from "vue";
import { filteredSyncVerifications } from "@/lib/parsers";
import RulesService from "@/api/rules/rulesService";
import AppDetailVerificationFlowInfo from "../AppDetailVerificationFlowInfo.vue";
import AppDetailIntegrationInfo from "../AppDetailIntegrationInfo.vue";
import AppDetailStyle from "../AppDetailStyle.vue";
import KYCVerificationRequirements from "./KYCRequirements/KYCVerificationRequirements.vue";
import AppDetailAdminsAndAccess from "../AppDetailAdminsAndAccess.vue";

export default Vue.extend({
  components: {
    AppDetailVerificationFlowInfo,
    AppDetailIntegrationInfo,
    AppDetailStyle,
    KYCVerificationRequirements,
    AppDetailAdminsAndAccess,
  },
  name: "AppDetailKYC",
  props: {
    verificationFlowProp: { required: false, type: Object },
    activeTabName: { required: false, type: String, default: "" },
    create: { required: false, type: Boolean, default: false },
    recurrentAMLOptions: { required: true, type: Array },
    loading: { required: true, type: Boolean },
    primaryColorHexFeedback: { required: true, type: String, default: "" },
    pushRequirement: { required: true, type: Function },
    verificationFlowType: { required: true, type: String },
  },
  data() {
    return {
      VERIFICATIONS_TYPES_VIEW_MAP: VERIFICATIONS_TYPES_VIEW_MAP,
      filteredSyncVerifications: filteredSyncVerifications,
      cappFilteredSyncVerifications: [[]] as Array<Array<VerificationAPIType>>,
      cappFilteredSyncVerificationsInput: [[]] as Array<
        Array<VerificationAPIType>
      >,
      hasAML: false,
      hasPEP: false,
      createRule: false,
      recurrentAMLValue: "" as string | undefined,
      rulesList: [],
      verificationsRequirements: [] as any,
      verificationFlow: this.verificationFlowProp || ({} as any),
    };
  },
  computed: {
    hasKYC(): boolean {
      for (let key of Object.keys(KYC_VERIFICATIONS_TYPES_VIEW_MAP)) {
        for (let vgroup of this.cappFilteredSyncVerifications) {
          if (vgroup.includes(key as VerificationAPIType)) {
            return true;
          }
        }
      }
      return false;
    },
  },
  watch: {
    verificationFlow: {
      handler() {
        this.$emit("updateVerificationFlow", this.verificationFlow);
      },
    },
    verificationFlowProp: {
      handler() {
        this.updateKYCVerificationFlow(this.verificationFlowProp);
        this.updateStatsOnVerificationFlowChange();
      },
      immediate: true,
    },
    activeTabName: {
      async handler() {
        if (
          this.activeTabName === "verification-requirements" &&
          !this.create
        ) {
          await this.getRules();
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateKYCVerificationFlow(verificationFlowEvent: any) {
      this.verificationFlow = {
        ...this.verificationFlow,
        ...verificationFlowEvent,
      };

      if (this.create) {
        this.verificationFlow.flow_type = "UDV";
        this.verificationFlow.company_id = this.$store.state.company.id;
      }

      this.verificationFlow = (({
        logo,
        has_accepted_conditions,
        api_token,
        app_id,
        company,
        created_at,
        updated_at,
        extra_documents,
        ...remainingProperties
      }) => remainingProperties)(this.verificationFlow);

      this.$emit("updateVerificationFlow", this.verificationFlow);
    },
    async getRules() {
      const res = await RulesService.getRules(this.verificationFlowProp.app_id);

      this.rulesList = res.data;
    },
    calculateKYCRequirements() {
      this.verificationsRequirements = this.filteredSyncVerifications(
        this.cappFilteredSyncVerificationsInput
      );

      this.pushRequirement(this.verificationsRequirements, this.hasAML, [
        "AML:background" +
          (this.recurrentAMLValue && this.recurrentAMLValue !== "Not Recurrent"
            ? `:${this.recurrentAMLValue}`
            : ""),
      ]);

      this.pushRequirement(this.verificationsRequirements, this.hasPEP, [
        "PEP:background",
      ]);

      this.updateKYCVerificationFlow({
        permissions: this.verificationsRequirements,
      });
    },
    updateKYCRequirementsConfig(verification?: any) {
      if (!verification)
        this.cappFilteredSyncVerificationsInput =
          this.filteredSyncVerifications(
            this.cappFilteredSyncVerificationsInput
          );
      else if (verification.key === "AML:background")
        this.hasAML = verification.value;
      else if (verification.key === "PEP:background")
        this.hasPEP = verification.value;

      this.cappFilteredSyncVerifications =
        this.cappFilteredSyncVerificationsInput;

      this.calculateKYCRequirements();
    },
    setAMLAndPEPRecursiveness(permissions: Array<any>, target: any) {
      for (let permissionsGroup of permissions) {
        const permissionsGroupSplit = permissionsGroup[0].split(":");

        const isAML = permissionsGroupSplit[0] === "AML";
        const isPEP = permissionsGroup.includes("PEP:background");

        if (isAML && permissionsGroupSplit[2]) {
          const foundOption: any = this.recurrentAMLOptions.find(
            (option: any) => permissionsGroupSplit[2] === option.value
          );
          if (foundOption) target.recurrentAMLValue = foundOption.value;
        } else if (isAML) target.recurrentAMLValue = "Not Recurrent";

        if (isPEP) target.hasPEP = true;
      }
    },
    setAMLAndPEPConditionsPermissions(companyAppPermissions: Array<any>) {
      this.hasAML = this.hasAML;
      this.hasPEP = this.hasPEP;
      this.recurrentAMLValue = this.recurrentAMLValue;
      this.setAMLAndPEPRecursiveness(companyAppPermissions, this);
    },
    async updateStatsOnVerificationFlowChange() {
      this.cappFilteredSyncVerificationsInput = this.filteredSyncVerifications(
        this.verificationFlowProp.permissions
      );
      this.cappFilteredSyncVerifications =
        this.cappFilteredSyncVerificationsInput;

      this.setAMLAndPEPConditionsPermissions(
        this.verificationFlowProp.permissions
      );
    },
  },
  async mounted() {
    this.updateStatsOnVerificationFlowChange();
  },
});
