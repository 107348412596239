









import Vue from "vue";

export default Vue.extend({
  name: "v-tab",
  props: {
    activeTabName: { required: false, type: String },
    checkIfIsSelected: { required: false, type: String },
    analyticsKeyEvent: { required: false, default: "" },
  },
  methods: {
    onClick() {
      this.$emit("onActiveTabChange", this.activeTabName);
      if (this.analyticsKeyEvent)
        this.$root.$emit("analytics", this.analyticsKeyEvent);
    },
  },
});
