import axios from "axios";
import * as Sentry from "@sentry/vue";
import Vue from "vue";
interface CountryValue {
  text: string;
  value: string;
}

interface CountriesListOptions {
  iso?: string;
}

export async function getCountriesList(options: CountriesListOptions = {iso: "iso3"}) {
  const { data } = await axios.get("https://countriesnow.space/api/v0.1/countries/flag/unicode");
  
  const countryValues = data.data.map((country :any) => {
    const { unicodeFlag, name, iso2, iso3 } = country;
    let isoCode = iso3
    if(options.iso === "iso2") isoCode = iso2
    
    const countryValue = {
      text: unicodeFlag + " " + name,
      value: isoCode,
      flag: unicodeFlag,
    } as CountryValue;

    return countryValue;
  });

  return countryValues;
}

export function sendSentryError(errorMessage: string | unknown) {
  Sentry.captureException(errorMessage);
  
  return
}

export function handleBeforeUnload(event: any) {
  event.preventDefault();
  event.returnValue = true;
}

const eventBus = new Vue();

Vue.prototype.$eventBus = eventBus;

export default eventBus;