
















































































import Vue from "vue";
import HomeAnalyticsCard from "./components/HomeAnalyticsCard.vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import UsersService from "@/api/users/usersService";
import HomeHowToCard from "./components/HomeHowToCard.vue";
import { VerificationAnalyticsResult } from "@/models/verificationsTypes";
import HomeRecentActivityCard from "./components/HomeRecentActivityCard.vue";
import CompaniesService from "@/api/companies/companiesService";
import { HTTP, HTTPAbortableAbort } from "@/lib/http/api";
import HomeAnalyticsCardSkeleton from "@/components/skeletons/home/HomeAnalyticsCardSkeleton.vue";
import HomeRecentActivityCardSkeleton from "@/components/skeletons/home/HomeRecentActivityCardSkeleton.vue";

type VFlowUsageResults = {
  name: string | null;
  id: string | null;
  verifications: VerificationAnalyticsResult[];
  checks: VerificationAnalyticsResult[];
  totalUsersVerified: number;
  totalCountOfCompaniesVerified: number;
  totalCountOfComplexDocsVerified: number;
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default Vue.extend({
  name: "Home",
  components: {
    HomeAnalyticsCard,
    VTab,
    HomeHowToCard,
    HomeRecentActivityCard,
    HomeAnalyticsCardSkeleton,
    HomeRecentActivityCardSkeleton,
  },
  data() {
    return {
      loading: false,
      startDate: new Date(new Date().setDate(1)),
      endDate: new Date(),
      groupBy: "month",
      companyAppSelected: this.$store.state.verificationFlow || {},
      verificationFlows: [] as Array<any>,
      allCompaniesUsageAccumulated: {} as VFlowUsageResults,
      companyAppHighlights: {} as any,
      loadingHighlights: false,
    };
  },
  watch: {
    "$store.state.companyAppId": {
      handler() {
        this.loadData();
      },
    },
    "$store.state.company": {
      handler() {
        this.loadData();
      },
    },
  },
  computed: {
    isAllCompanies(): boolean {
      return this.$store.state.company === "all-companies";
    },
    groupByOptions(): Array<any> {
      return [
        { value: "day", text: this.$t("day") },
        { value: "week", text: this.$t("week") },
        { value: "month", text: this.$t("month") },
      ];
    },
    isRecentActivityOpened(): Boolean {
      return this.$route.name === "recent-activity";
    },
    isCDPFlowType(): boolean {
      return this.$store.state.verificationFlow.flow_type === "CDP";
    },
  },
  methods: {
    abortAllCompanyVerificationsRequest() {
      HTTPAbortableAbort();
    },
    filterAndParseVerifications(companies: Array<any>): VFlowUsageResults[] {
      let totalCountOfVerification = 0;
      const updatedCompanies: VFlowUsageResults[] = [];
      if (!companies) return updatedCompanies;
      this.allCompaniesUsageAccumulated = {
        name: null,
        id: "allCompaniesUsageAccumulated",
        verifications: [],
        checks: [],
        totalUsersVerified: 0,
        totalCountOfCompaniesVerified: 0,
        totalCountOfComplexDocsVerified: 0,
      };
      for (const company of companies) {
        const companyVerifications = company.verifications;
        company.checks = [];
        company.verifications = [];
        company.totalVerificationsCount = 0;
        company.totalUsersVerified = 0;
        company.totalCountOfCompaniesVerified = 0;
        company.totalCountOfComplexDocsVerified = 0;
        for (const verification of companyVerifications) {
          for (const timePeriod of verification.time_periods) {
            if (timePeriod.count !== 0)
              totalCountOfVerification += timePeriod.count;
          }
          if (totalCountOfVerification) {
            if (verification.type === "Verified Users") {
              verification.type = "verified_users";
              company.totalUsersVerified = totalCountOfVerification;
              this.allCompaniesUsageAccumulated["totalUsersVerified"] +=
                totalCountOfVerification;
            }
            if (verification.type === "REPR") {
              company.totalCountOfCompaniesVerified = totalCountOfVerification;
              this.allCompaniesUsageAccumulated[
                "totalCountOfCompaniesVerified"
              ] += totalCountOfVerification;
            }
            if (["UPOA", "CPOA", "CDP"].includes(verification.type)) {
              company.totalCountOfComplexDocsVerified =
                totalCountOfVerification;
              this.allCompaniesUsageAccumulated[
                "totalCountOfComplexDocsVerified"
              ] += totalCountOfVerification;
            }
            verification.totalCount = totalCountOfVerification;
            if (
              ["UDV", "verified_users", "REPR", "UPOA", "CPOA"].includes(
                verification.type
              )
            ) {
              company.totalVerificationsCount += totalCountOfVerification;
              company.verifications.push(verification);
            } else {
              company.checks.push(verification);
            }
            totalCountOfVerification = 0;
          }
        }
        if (company.checks.length || company.verifications.length) {
          updatedCompanies.push(company);
        }
      }
      return updatedCompanies;
    },
    loadHomeAnalyticsCardData(res: any) {
      let auxVerificationFlows = [];
      if (this.$store.state.company === "all-companies") {
        auxVerificationFlows = this.filterAndParseVerifications(res.companies);
      } else if (
        this.$store.state.companyAppId !== "all" &&
        res.company_apps &&
        res.company_apps.length
      ) {
        const companyAppSelected = res.company_apps.filter(
          (companyApp: any) => companyApp.id === this.$store.state.companyAppId
        );
        auxVerificationFlows =
          this.filterAndParseVerifications(companyAppSelected);
      } else {
        auxVerificationFlows = this.filterAndParseVerifications(
          res.company_apps
        );
      }
      this.verificationFlows = auxVerificationFlows.sort(
        (a: any, b: any) =>
          b.totalVerificationsCount - a.totalVerificationsCount
      );
    },
    async loadData() {
      if (this.loading) {
        this.abortAllCompanyVerificationsRequest();
      }
      try {
        this.loading = true;
        const startDate = new Date(this.startDate);
        const endDate = new Date(this.endDate);
        endDate.setDate(endDate.getDate() + 1);
        const searchParams: URLSearchParams = new URLSearchParams();
        const fromDateString = `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`;
        const toDateString = `${endDate.getFullYear()}-${
          endDate.getMonth() + 1
        }-${endDate.getDate()}`;
        searchParams.set("from_date", fromDateString);
        searchParams.set("to_date", toDateString);
        searchParams.set("group_by", this.groupBy);
        let res: any = { data: { results: [] } };
        let resHighlights: any = { data: { results: [], next: "" } };
        if (this.$store.state.company === "all-companies") {
          res = await UsersService.getTotalVerificationsAllCompanies(
            searchParams
          );
        } else if (this.$store.state.company && this.$store.state.company.id) {
          res = await UsersService.getTotalVerificationsFromCompany(
            searchParams,
            this.$store.state.company.id
          );
          if (this.$store.state.companyAppId === "all") {
            resHighlights = await CompaniesService.getCompanyHighlights(
              this.$store.state.company.id
            );
          } else if (!this.isCDPFlowType) {
            resHighlights = await CompaniesService.getCompanyAppHighlights(
              this.$store.state.companyAppId
            );
          }
        }
        this.loadHomeAnalyticsCardData(res);
        this.companyAppHighlights = resHighlights.data;
        this.loading = false;
      } catch (error: any) {
        if (error.code !== "ERR_CANCELED") this.loading = false; // Works because if we abort passed requests it's because there's another request going on
      }
    },
    async loadMoreHighLights() {
      this.loadingHighlights = true;
      if (!this.companyAppHighlights.next.startsWith("https"))
        this.companyAppHighlights.next = this.companyAppHighlights.next.replace(
          "http",
          "https"
        );
      const res = await HTTP.get(this.companyAppHighlights.next);
      this.companyAppHighlights.next = res.data.next;
      const responseResults = res.data.results;
      if (Array.isArray(this.companyAppHighlights.results)) {
        this.companyAppHighlights.results.push(...responseResults);
      } else {
        for (const key in responseResults) {
          if (Array.isArray(responseResults[key])) {
            if (Array.isArray(this.companyAppHighlights.results[key])) {
              this.companyAppHighlights.results[key].push(
                ...responseResults[key]
              );
            } else {
              this.companyAppHighlights.results[key] = responseResults[key];
            }
          }
        }
      }
      this.loadingHighlights = false;
    },
    downloadFile() {
      const startDate = new Date(this.startDate);
      const startDateFormatted = (this.$options as any).filters.date(
        this.startDate
      );
      const endDateFormatted = (this.$options as any).filters.date(
        this.endDate
      );

      let htmlTemplate = `
          <html>
            <head>
                <style>
                  html * {
                    color: #333;
                    font-family: Roboto, Arial !important;
                  }
                  body {
                    font-size: 1em !important;
                    padding: 40px;
                  }
                  ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                  }
                  h2 {
                    margin-top: 0;
                  }
                  h3 {
                    margin: 0;
                    border: 1px solid #000;
                    padding: 5px 10px;
                    border-radius: 10px;
                  }
                  b {
                    margin: 0;
                    border-bottom: 1px solid #000;
                  }
                  .verification-flow-wrapper {
                    padding: 20px 0;
                    border-bottom: 1px solid #000;
                    display: flex;
                    gap: 20px;
                    align-items: flex-start;
                  }
                  .verification-type-header {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                  }
                  .verification-flow-wrapper:last-child {
                    border-bottom: none;
                  }
                </style>
            </head>
            <body>
                <h1>
                    Silt Usage Report for ${monthNames[startDate.getMonth()]}
                </h1>
                <p>
                    Time Period:
                    <span>
                        ${startDateFormatted}
                    </span>
                    -
                    <span id="endDate">
                        ${endDateFormatted}
                    </span>
                </p>
        `;

      for (const verificationFlow of this.verificationFlows) {
        let totalPrice = 0;
        htmlTemplate += `
              <div class="verification-flow-wrapper">
              <div>
              <h2>
                ${verificationFlow.company_app || verificationFlow.name}
                -
                (${verificationFlow.id})
              </h2>
            `;
        for (const verification of verificationFlow.verifications) {
          if (verification.type === "verified_users") {
            htmlTemplate += `
              <div>
                <div class="verification-type-header">
                  <h4>Verified Users</h4>
                  <b>Total: ${Math.max(
                    verification.totalCount * 0.5,
                    99
                  )}€ + IVA
                  </b>
                </div>
                <ul>
                  <li>Unidades: ${verification.totalCount}</li>
                  <li>Precio Unitario: 0,50€</li>
                  <li>Cuota mínima: 99€</li>
                </ul>
              </div>
            `;
            totalPrice += Math.max(verification.totalCount * 0.5, 99);
          }
        }
        for (const check of verificationFlow.checks) {
          if (check.type === "AML" || check.type === "PEP") {
            htmlTemplate += `
              <div>
                <div class="verification-type-header">
                  <h4>${check.type}</h4>
                  <b>Total: ${check.totalCount * 0.25}€ + IVA</b>
                </div>
                <ul>
                  <li>Unidades: ${check.totalCount}</li>
                  <li>Precio Unitario: 0,25€</li>
                </ul>
              </div>
            `;
            totalPrice += check.totalCount * 0.25;
          }
        }
        htmlTemplate += `</div><h3>Total factura: ${totalPrice}€ + IVA</h3></div>`;
      }

      htmlTemplate += `</div></body></html>`;

      const file = new Blob([htmlTemplate], {
        type: "text/html; charset=UTF-8",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = `Silt-${monthNames[startDate.getMonth()]}-usage.html`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    },
  },
  mounted() {
    this.loadData();
  },
  beforeDestroy() {
    this.abortAllCompanyVerificationsRequest();
  },
});
